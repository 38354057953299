import React, { useState,useRef } from 'react';
import './Est.css';
import { Button, Datepick, Flex, Flexed, Input, Label, Selectbox } from '../../Shared';
import { useEffect } from 'react';
import axios from 'axios';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import Pocketfolder from './Pocketfolder';
import Books from './Books';
import { machines } from '../../Prices/Information';
import PrintComponent from '../../print/Quoteletter';
import Allbids from './Allbids';
import Pocketfolders from './Pocketfolders';
import Mini from './Mini';
import { BookEstimateProvider } from '../../Context/BookEstimate';
import { useParams, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';


function Estimate() {


    const initialstate = {
        pocketfolder:[] ,
        embossing:[] ,
        diecutting:[] ,
    }
const [selectedIndex, setSelectedIndex] = useState(0);
const [customers, setCustomers] = useState([])
const [quoteinfo, setQuoteinfo] = useState({})
const [selectedCustomer, setSelectedCustomer] = useState({})
const [billto, setBillto] = useState({})
const [shipto, setShipto] = useState({})
const [todayDate, setTodayDate] = useState(new Date())
const [dueDate, setDueDate] = useState()     
const [billaddresses, setBilladdresses] = useState([])   
const [shipaddresses, setShipaddresses] = useState([])   
const [bids, setBids] = useState([])
const [estitems, setEstitems] = useState(initialstate)
const [reset, setReset] = useState(false)
const [savedbooks, setSavedbooks] = useState([])
const [tempquan, setTempquan] = useState("")
const [quotenum, setQuotenum] = useState(null)
const [quote, setQuote] = useState({})
const quanbox = useRef()   
const savebox = useRef()   
const nav = useNavigate()
const param = useParams()
const [info, setInfo] = useState({
    pockets:null,
    sheets:null,
    pieces:null,
    cost:null,
    price:null,
    cuttingdiecost:null,
    cuttingdieprice:null,
    cuttingdiequan:null,
    cuttingdienumber:null,
    cuttingdiemr:null,
    boxcost:5
})

  useEffect(() => {
if(param.id){

const getquote = async() => {
    let r ={
        id:param.id
    }
  
//await axios.post('http://localhost:5009/quotes/getquote',r)
await axios.post('https://cfpapi-6ab062006fa0.herokuapp.com/quotes/getquote',r)

.then(res => {
    console.log(res.data)
    let bill = res.data[0]?.customer.address.filter(filt => filt.itemid === res.data[0]?.billto)
    let ship = res.data[0]?.customer.address.filter(filt => filt.itemid === res.data[0]?.shipto)
setSelectedCustomer(res.data[0]?.customer)
setTodayDate(new Date(res.data[0]?.createdAt))
setDueDate(new Date(res.data[0]?.duedate))
setBillto(bill[0] ||  res.data[0]?.billtofallback )
setShipto(ship[0] ||  res.data[0]?.shiptofallback )
setQuoteinfo(res.data[0]?.info)
setSavedbooks(res.data[0]?.books)
setQuote(res.data[0])
})
.catch(err => console.log(err))
}
getquote()
}
  },[param.id])






    useEffect(() => {

const getcustomers = async() => {
    //await axios.get('http://localhost:5009/customers/getall')
    await axios.get('https://cfpapi-6ab062006fa0.herokuapp.com/customers/getall')
    .then(res => setCustomers(res.data))
    .catch(err => console.log(err))
}

getcustomers()
    },[])


    const setcustomer = (e,node) => {
        let customerdata
        let inputValue = e.target.value
        var options = document.getElementById(node).childNodes;
        for (var i = 0; i < options.length; i++) {
            if (options[i].value.toLowerCase() === inputValue.toLowerCase()) {
              let dd = options[i].dataset
                customerdata ={
               ...dd, 
             }
            }
      } 
console.log(customerdata)
      let cust = customers.filter(filt => filt._id === customerdata?.itemid)
console.log(cust)
setSelectedCustomer(cust[0])
setBilladdresses(cust[0]?.address)
setShipaddresses(cust[0]?.address)
setBillto(cust[0]?.address[0])
setShipto(cust[0]?.address[0]) 

    }  


    const changebillto = (e,node) => {
        let customerdata
        let inputValue = e.target.value
        var options = document.getElementById(node).childNodes;
        for (var i = 0; i < options.length; i++) {
            if (options[i].value.toLowerCase() === inputValue.toLowerCase()) {
              let dd = options[i].dataset
                customerdata ={
               ...dd, 
             }
            }
      } 

let cust = billaddresses?.filter(filt => filt.name === customerdata?.name)
console.log(cust)
if(cust[0]){
    setBillto(cust[0])
}else{
    setBillto({name:e.target.value,address1:"",address2:"",city:"",state:"",zip:""})   
}
    }

    const changeshipto = (e,node) => {
        let customerdata
        let inputValue = e.target.value
        var options = document.getElementById(node).childNodes;
        for (var i = 0; i < options.length; i++) {
            if (options[i].value.toLowerCase() === inputValue.toLowerCase()) {
              let dd = options[i].dataset
                customerdata ={
               ...dd, 
             }
            }
      } 

let cust = shipaddresses?.filter(filt => filt.name === customerdata?.name)
console.log(cust)
if(cust[0]){
    setShipto(cust[0])
}else{
   setShipto({name:e.target.value,address1:"",address2:"",city:"",state:"",zip:""})   
}
    }


    const handleTabClick = (index) => {  
          setSelectedIndex(index);
      };



      const savequote = async(e) => {
e.preventDefault()
let r = uuidv4()
let bill = {name:billto.name,address1:billto.address1,address2:billto.address2,city:billto.city,state:billto.state,zip:billto.zip}
let ship = {name:shipto.name,address1:shipto.address1,address2:shipto.address2,city:shipto.city,state:shipto.state,zip:shipto.zip}
console.log(r)
let q = {
    customer:selectedCustomer._id,
    billto:billto.itemid,
    shipto:shipto.itemid,
    billtofallback:bill,
    shiptofallback:ship,
  books:savedbooks,
  duedate:dueDate,
  info:quoteinfo
}



   // axios.post('http://localhost:5009/quotes/addquote',q)
    await axios.post('https://cfpapi-6ab062006fa0.herokuapp.com/quotes/addquote',q)
   
    .then(res => {
        toast.success('     Quote has been saved. Quote # '+res.data.quotenum)
nav('/estimate/'+res.data.quotenum)

    })
    .catch(err => console.log(err))
      }


const updatequote = (e) => {
    e.preventDefault()
    let bill = {name:billto.name,address1:billto.address1,address2:billto.address2,city:billto.city,state:billto.state,zip:billto.zip}
    let ship = {name:shipto.name,address1:shipto.address1,address2:shipto.address2,city:shipto.city,state:shipto.state,zip:shipto.zip}
    
    let q = {
        id:quote._id,
        quotenum:quote.quotenum,
        customer:selectedCustomer._id,
        billto:billto.itemid,
        shipto:shipto.itemid,
        billtofallback:bill,
        shiptofallback:ship,
      books:savedbooks,
      duedate:dueDate,
      info:quoteinfo
    }

    
    axios.post('https://cfpapi-6ab062006fa0.herokuapp.com/quotes/updatequote',q)
   // axios.post('http://localhost:5009/quotes/updatequote',q)
    .then(res => {
        console.log(res.data)   
       // toast.success('     Quote has been saved. Quote # '+res.data.quotenum)
//nav('/estimate/'+res.data.quotenum)

    })
    .catch(err => console.log(err))
      }

  console.log(quoteinfo)
  return (
    <>
    <BookEstimateProvider>
        <form onSubmit={e => param.id ? updatequote(e) : savequote(e)}>
   <Flexed>
    <Flex fontSize="calc(1.3rem + .2vw)">Estimator</Flex>
    <Flex><Button >{param.id? "Update Quote" : "Save Quote"}</Button></Flex>
     <Flex><PrintComponent quotenum={quotenum} todayDate={todayDate} dueDate={dueDate} quoteinfo={quoteinfo} shipto={shipto} billto={billto} estitems={estitems} selectedCustomer={selectedCustomer} savedbooks={savedbooks}/></Flex> 
   </Flexed>

   <Flexed>
<Flex flex="2"><Label>Customer</Label><br />
    <Input  type="text"    list="customers"  value={selectedCustomer?.name} onChange={e => setcustomer(e,"customers")} required/>
    <datalist id="customers">

{
    customers?.map((item,index) => {
     return   <option key={index}   data-name={item?.name} data-itemid={item?._id} value={`${item?.name}`}/> 
    })
}
   </datalist>
</Flex>
<Flex flex=".5"></Flex>
<Flex flex=".5"><Label>Todays Date</Label><br /><Datepick selected={todayDate} onChange={date => setTodayDate(date)} /></Flex>
<Flex flex=".5"></Flex>
<Flex flex=".5"><Label>Due Date</Label><br /><Datepick selected={dueDate} onChange={date => setDueDate(date)} required/></Flex>
   </Flexed>

<Flexed>
    <Flex>Job Name<br /><Input type="text" value={quoteinfo?.jobname} onChange={e => setQuoteinfo({...quoteinfo,jobname:e.target.value})} required/></Flex>
    <Flex>Customer Job Number<br /><Input type="text" value={quoteinfo?.custjobnum} onChange={e => setQuoteinfo({...quoteinfo,custjobnum:e.target.value})}/></Flex>
    <Flex>Customer P.O. #<br /><Input type="text" value={quoteinfo?.custponum} onChange={e => setQuoteinfo({...quoteinfo,custponum:e.target.value})}/></Flex>
</Flexed>
<Flexed gap="40px" margin="20px 0px 0px 0px" borderBottom="3px solid #4EC0AC">
<Flex>
    <Flexed><Flex><Label fontSize="calc(1.1rem + .2vw)">Bill To:</Label></Flex><Flexed><Flex>   
        <Input  type="text"    list="billtoaddress"  defaultValue={billto?.name} onChange={e => changebillto(e,"billtoaddress")} required/>
    <datalist id="billtoaddress">
{
    billaddresses?.map((item,index) => {
     return   <option key={index}   data-name={item?.name} data-itemid={item?._id} value={`${item?.name}`}/> 
    })
}
   </datalist></Flex></Flexed></Flexed>
     <Flexed>
    <Flex flex="2" ><Label>Address 1</Label><br /><Input value={billto?.address1} onChange={e => setBillto({...billto,address1:e.target.value})}/></Flex>
    <Flex flex="2" ><Label>Address 2</Label><br /><Input value={billto?.address2} onChange={e => setBillto({...billto,address2:e.target.value})}/></Flex>
    </Flexed> 
    <Flexed>
    <Flex flex="1.7"><Label>City</Label><br /><Input value={billto?.city}  onChange={e => setBillto({...billto,city:e.target.value})}/></Flex>
    <Flex><Label>State</Label><br /><Input value={billto?.state} onChange={e => setBillto({...billto,state:e.target.value})} /></Flex>
    <Flex flex=".5" ><Label>Zip</Label><br /><Input  value={billto?.zip}  onChange={e => setBillto({...billto,zip:e.target.value})}/></Flex>
    </Flexed></Flex>


    <Flex>
    <Flexed><Flex><Label fontSize="calc(1.1rem + .2vw)">Ship To:</Label></Flex><Flexed><Flex>   
        <Input  type="text"    list="shiptoaddress"  defaultValue={shipto?.name} onChange={e => changeshipto(e,"shiptoaddress")} required/>
    <datalist id="shiptoaddress">
{
    shipaddresses?.map((item,index) => {
     return   <option key={index}   data-name={item?.name} data-itemid={item?._id} value={`${item?.name}`}/> 
    })
}
   </datalist></Flex></Flexed></Flexed>
     <Flexed>
    <Flex flex="2" ><Label>Address 1</Label><br /><Input value={shipto?.address1} onChange={e => setShipto({...shipto,address1:e.target.value})}/></Flex>
    <Flex flex="2" ><Label>Address 2</Label><br /><Input value={shipto?.address2} onChange={e => setShipto({...shipto,address2:e.target.value})}/></Flex>
    </Flexed> 
    <Flexed>
    <Flex flex="1.7"><Label>City</Label><br /><Input value={shipto?.city} onChange={e => setShipto({...shipto,city:e.target.value})}/></Flex>
    <Flex><Label>State</Label><br /><Input value={shipto?.state} onChange={e => setShipto({...shipto,state:e.target.value})}/></Flex>
    <Flex flex=".5" ><Label>Zip</Label><br /><Input  value={shipto?.zip} onChange={e => setShipto({...shipto,zip:e.target.value})}/></Flex>
    </Flexed></Flex>
</Flexed>
 

{
!param.id && <p style={{color:"red",textAlign:"center",fontSize:"1.5rem"}}>Quote Not Saved</p>
}
<Flexed gap="20px" margin="25px 0px 0px 0px">
    <Flex flex="2">
 <Tabs selectedIndex={selectedIndex} onSelect={handleTabClick}>
 <TabList> 
    {/* <Tab>Bids</Tab>
    <Tab>Pocket Folders</Tab>
    <Tab>Old Pocket Folder</Tab>
    <Tab>Mini</Tab> */}
     <Tab>Quote Items</Tab>
    <Tab>Books Development</Tab>
    </TabList>
    {/* <TabPanel><Allbids savedbooks={savedbooks} /></TabPanel>
        <TabPanel><Pocketfolder estitems={estitems} setEstitems={setEstitems} reset={reset} setReset={setReset}/></TabPanel>
        <TabPanel><Pocketfolders bids={bids} setBids={setBids} info={info} setInfo={setInfo} estitems={estitems} setEstitems={setEstitems}/></TabPanel>
        <TabPanel><Books /></TabPanel> */}
        <TabPanel><Allbids setSavedbooks={setSavedbooks} savedbooks={savedbooks} selectedIndex={selectedIndex} setSelectedIndex={setSelectedIndex}/></TabPanel>
         <TabPanel><Books setSavedbooks={setSavedbooks} savedbooks={savedbooks} setSelectedIndex={setSelectedIndex} selectedIndex={selectedIndex}/></TabPanel>
         
        <TabPanel>5</TabPanel>
    
 </Tabs>
 </Flex>
 </Flexed>

 </form>
 </BookEstimateProvider>
   </>
  );
}

export default Estimate;

