
import { useContext, useEffect, useRef, useState } from 'react'
import {useParams } from 'react-router-dom'
import { Button, currency, Flex, Flexed, Input, Label, Scroll, Selectbox, Wrapper } from '../../Shared'
import {boxes, paperthickness, plastikol, wireo} from './Calcs'
import { FiMinusCircle } from "react-icons/fi";
import Modal from '../../modals/Modal';
import Adddiecut from './Adddiecut';
import Addemboss from './Addemboss';
import Embossing from './Embossing';
import { coilcost, setboxes } from './Processes';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import Allbids from './Allbids';
import { v4 as uuidv4 } from 'uuid';
import Bookitem from './Bookitem';
import BookEstimate from '../../Context/BookEstimate';
import Collapse from '../../Util/Collapse'
import { toast } from 'react-toastify';



const Books = ({setSavedbooks,savedbooks,selectedIndex,setSelectedIndex}) => {

 
  const [activeIndex, setActiveIndex] = useState([]);

 
 
  const [binding, setBinding] = useState({type:"Wire-O",thickness:.125});



  const param = useParams()
  const {items, setItems,setCurrentbookbook,checkquans,quanbox,other,setOther,covers,setCovers,text,setText,thickness, setThickness,setAdditionalthickness,totalpages,holes,setHoles,tabs,setTabs,changecoilinfo,slipsheet,setSlipsheet, punchmr,setPunchmr,coilmr,setCoilmr,currentbook,bookinfo,setBookinfo,coilinfo,setCoilinfo,getlabor,gettotlabor,getmat,gettotmat} = useContext(BookEstimate)



 



  const removequantity = (id) => {

 let a = items.filter(filt => filt.id !== id)
 setItems(a)

 }


 const savebook = () => {
  let g = {
    bookname:'Book '+(savedbooks?.length + 1),
    additionalthickness:bookinfo?.additionalthickness,
coverpages:bookinfo?.coverpages,
coverthickness:bookinfo?.coverthickness,
covername:bookinfo?.covername,
length:bookinfo?.length,
spine:bookinfo?.spine,
holes:bookinfo?.holes,
textname:bookinfo?.textname,
textpages:bookinfo?.textpages,
textthickness:bookinfo?.textthickness,
othername:bookinfo?.othername,
otherpages:bookinfo?.otherpages,
otherthickness:bookinfo?.otherthickness,
thickness:bookinfo?.thickness,
width:bookinfo?.width,
totalpages:bookinfo?.totalpages,
collatepu:bookinfo?.collatepu,
coilmr:bookinfo?.coilmr,
punchmr:bookinfo?.punchmr,
tabs:bookinfo?.tabs,
cuts:bookinfo?.cuts,
slipsheet:bookinfo?.slipsheet,
insert:bookinfo?.insert,
    id:uuidv4(),
    items:items
  }


setSavedbooks([...savedbooks,g])
//  setItems([])
//  setBookinfo({})
 toast.success('Book Info Saved.')

 }

 const updatebook = () => {
  let g = {
    bookname:bookinfo?.bookname,
    additionalthickness:bookinfo?.additionalthickness,
coverpages:bookinfo?.coverpages,
coverthickness:bookinfo?.coverthickness,
covername:bookinfo?.covername,
length:bookinfo?.length,
spine:bookinfo?.spine,
holes:bookinfo?.holes,
textname:bookinfo?.textname,
textpages:bookinfo?.textpages,
textthickness:bookinfo?.textthickness,
othername:bookinfo?.othername,
otherpages:bookinfo?.otherpages,
otherthickness:bookinfo?.otherthickness,
thickness:bookinfo?.thickness,
width:bookinfo?.width,
coilmr:bookinfo?.coilmr,
punchmr:bookinfo?.punchmr,
totalpages:bookinfo?.totalpages,
collatepu:bookinfo?.collatepu,
tabs:bookinfo?.tabs,
cuts:bookinfo?.cuts,
slipsheet:bookinfo?.slipsheet,
insert:bookinfo?.insert,
id:bookinfo?.id,
    items:items
  }

let gg = [...savedbooks]
 let h =  gg.findIndex((obj => obj.id === bookinfo?.id))
gg[h] = g

setSavedbooks(gg)
// setBookinfo({...bookinfo,id:null})
//  setItems([])
 toast.success('Book Info Updated.')


 }

const newbook = () => {
  setBookinfo({...bookinfo,id:null})
 setItems([])
}



 
console.log(bookinfo)




  return (
   
<div style={{background:"#B0C4DE",padding:"10px 0px ",margin:"0"}}>

<Flexed>
<Flex flex=".7">
<Flexed  alignItems="flex-start">
  <Flex ><Label>Width</Label><br />  <Input type="text" value={bookinfo?.length} onChange={e => setBookinfo({...bookinfo,length:e.target.value})}/></Flex>
  <Flex ><Label>Length</Label><br />   <Input type="text" value={bookinfo?.width} onChange={e => setBookinfo({...bookinfo,width:e.target.value})}/></Flex>
  <Flex flex=".3">
   Spine<br /><Input type="text" value={bookinfo?.spine} onChange={e => setBookinfo({...bookinfo,spine:e.target.value})}/>
  </Flex>
</Flexed>


      </Flex>
      <Flex flex=".1">&nbsp;</Flex>
  <Flex>    <Flexed >
      <Flex flex=".7" textAlign="right">Text</Flex><Flex  flex="3"><Input height="25px" type=""  value={bookinfo?.textpages} onChange={e => setBookinfo({...bookinfo,textpages:e.target.value})}/></Flex>
      </Flexed>
      <Flexed >
      <Flex flex=".7" textAlign="right">Stock</Flex><Flex flex="3" >
      <Selectbox onChange={e => setBookinfo({...bookinfo,textthickness:e.target.value,textname:e.target.options[e.target.selectedIndex].text})} value={bookinfo?.textthickness}> 
<option value="0" selected >Select Paper</option>
  {
    paperthickness?.map((paper,index) => {
     return <option key={index} value={paper.thickness}>{paper.name} &nbsp;&nbsp;&nbsp;-&nbsp;&nbsp;&nbsp; {paper.thickness}</option>
    })
  }
  </Selectbox>
      </Flex>
      </Flexed></Flex>

  <Flex>      <Flexed>
      <Flex  flex=".7" textAlign="right" >Covers</Flex><Flex flex="3"><Input height="25px" type="" value={bookinfo?.coverpages} onChange={e => setBookinfo({...bookinfo,coverpages:e.target.value})} /></Flex>
      </Flexed>
      <Flexed >
      <Flex flex=".7" textAlign="right">Stock</Flex><Flex flex="3" > <Selectbox onChange={e => setBookinfo({...bookinfo,coverthickness:e.target.value,covername:e.target.options[e.target.selectedIndex].text})} value={bookinfo?.coverthickness}> 
<option value="0" selected >Select Paper</option>
  {
    paperthickness?.map((paper,index) => {
     return <option key={index} value={paper.thickness}>{paper.name} &nbsp;&nbsp;&nbsp;-&nbsp;&nbsp;&nbsp; {paper.thickness}</option>
    })
  }
  </Selectbox></Flex>
      </Flexed></Flex>

      <Flex>         <Flexed>
      <Flex flex=".7" textAlign="right" >Other</Flex ><Flex flex="3"><Input height="25px" type="" value={bookinfo?.otherpages} onChange={e => setBookinfo({...bookinfo,otherpages:e.target.value})}/></Flex>
      </Flexed>
      <Flexed >
      <Flex flex=".7" textAlign="right">Stock</Flex><Flex flex="3" > <Selectbox onChange={e => setBookinfo({...bookinfo,otherthickness:e.target.value,othername:e.target.options[e.target.selectedIndex].text})} value={bookinfo?.otherthickness}> 
<option value="0" selected >Select Paper</option>
  {
    paperthickness?.map((paper,index) => {
     return <option key={index} value={paper.thickness}>{paper.name} &nbsp;&nbsp;&nbsp;-&nbsp;&nbsp;&nbsp; {paper.thickness}</option>
    })
  }
  </Selectbox></Flex>
      </Flexed></Flex>
</Flexed>


<Flexed margin="-10px 0px 0px 0px">
<Flex >Binding
      <Selectbox value={binding?.thickness} onChange={e => setBinding({...binding,thickness:e.target.value,type:e.target.options[e.target.selectedIndex].text})} > 
      <option value="" >None</option>
<option value={.125} >Wire-O</option>
<option value={0.0787} >Plastikol</option>
  </Selectbox>
      </Flex>

      {
       binding.type === "Wire-O" ? 
       <> <Flex flex=".5"  >Coil Size:<br />
       <Selectbox width="100%" value={coilinfo?.decimal} onChange={e => changecoilinfo(e.target.value)} >
         <option value="" selected></option>
 {
   wireo?.map(item => {
 return <option value={item.decimal} selected>{item.fraction}</option>
   })
 }
       </Selectbox>
      </Flex>
     <Flex  flex=".5"> Pitch:<br /><Input type="text"  width="50%" value={coilinfo?.pitch} onChange={e => setCoilinfo({...coilinfo,pitch:e.target.value})}/>:1</Flex>
     <Flex  flex=".5"> Holes:<br /><Input type="text"  width="50%"  value={bookinfo?.holes} onChange={e => setBookinfo({...bookinfo,holes:e.target.value})}/></Flex>
     <Flex > Color:<br /><Selectbox >
       <option value="White">White</option>
       <option value="Black">Black</option>
       <option value="Blue">Blue</option>
       <option value="Green">Green</option>
       <option value="Red">Red</option>
       </Selectbox></Flex>
       <Flex>Loops / Roll<br /><Input  type="text" value={coilinfo?.loopsperroll} disabled/></Flex>
       <Flex>Roll Cost<br /><Input  type="text" value={coilinfo?.spoolcost} onChange={e => setCoilinfo({...coilinfo,spoolcost:e.target.value})} /></Flex>
       <Flex>Box Cost / 100<br /><Input type="text" value={coilinfo?.boxcost} onChange={e => setCoilinfo({...coilinfo,boxcost:e.target.value})}/></Flex></>
       :
<> <Flex flex=".5"  >Coil Size:<br />
      <Selectbox width="100%" value={coilinfo?.decimal} onChange={e => changecoilinfo(e.target.value)} >
        <option value="" selected></option>
{
  plastikol?.map(item => {
return <option value={item.decimal} selected>{item.fraction}</option>
  })
}
      </Selectbox>
     </Flex>
    <Flex  flex=".5"> Pitch:<br /><Input type="text"  width="50%" value={coilinfo?.pitch} onChange={e => setCoilinfo({...coilinfo,pitch:e.target.value})}/>:1</Flex>
    <Flex  flex=".5"> Holes:<br /><Input type="text"  width="50%"  value={holes} onChange={e => setHoles(e.target.value)}/></Flex>
    <Flex > Color:<br /><Selectbox >
      <option value="White">White</option>
      <option value="Black">Black</option>
      <option value="Blue">Blue</option>
      <option value="Green">Green</option>
      <option value="Red">Red</option>
      </Selectbox></Flex>
      <Flex flex=".5">Length:<br /><Selectbox >
      <option value="12">12</option>
      <option value="18">18</option>
      <option value="36">36</option>
    
      </Selectbox></Flex>
      
      <Flex>Box Cost / 100<br /><Input type="text" value={coilinfo?.boxcost}  /></Flex></>
      }
     
    
</Flexed>

<Flexed margin="10px 0px 0px 0px" >


         
      
          <Flex flex=".3"  >Tabs<br /><Input height="25px" type="text" width="50%" value={bookinfo?.tabs} onChange={e => setBookinfo({...bookinfo,tabs:e.target.value})} /></Flex>
 <Flex flex=".3" height="48px"><u>Slip Sheet</u><br /><Input height="20px" type="checkbox" checked={bookinfo?.slipsheet} onChange={e => setBookinfo({...bookinfo,slipsheet:e.target.checked})} width="60%" style={{position:"absolute",bottom:"4px",left:"-19%"}}/></Flex>
 <Flex flex=".3" ><u>Collate P/U</u><br /><Input height="25px" type="text" value={bookinfo?.collatepu} width="60%"  onChange={e => setBookinfo({...bookinfo,collatepu:e.target.value})}/></Flex>
 <Flex flex=".3" ><u>Insert</u><br /><Input height="25px" type="text" value={bookinfo?.insert} width="60%"  onChange={e => setBookinfo({...bookinfo,insert:e.target.value})}/></Flex>
 <Flex flex=".3"  >Cuts<br /><Input height="25px" type="text" width="50%" value={bookinfo?.cuts} onChange={e => setBookinfo({...bookinfo,cuts:e.target.value})} /></Flex>
 <Flex flex=".3" ><u>Punch M/R</u><br /><Input height="25px" type="text" value={bookinfo?.punchmr} width="60%"  onChange={e => setBookinfo({...bookinfo,punchmr:e.target.value})}/></Flex>
 <Flex flex=".3" ><u>Coiling M/R</u><br /><Input height="25px" type="text" value={bookinfo?.coilmr} width="60%"  onChange={e => setBookinfo({...bookinfo,coilmr:e.target.value})}/></Flex>
        <Flex flex=".3" ><u>Total Pages</u><br /><Input height="25px" type="text" value={bookinfo?.totalpages} width="60%"  disabled/></Flex>
      <Flex flex=".3"><u>Thickness</u><br /><Input height="25px" type="text" value={bookinfo?.thickness} width="60%"  disabled/></Flex>
      <Flex flex=".3"><u>Add'l "</u><br /><Input height="25px" type="text" value={bookinfo?.additionalthickness} width="60%" onChange={e => setBookinfo({...bookinfo,additionalthickness:e.target.value})}/></Flex>
</Flexed>





<hr style={{border:"1.5px solid grey"}}/>

<Flexed alignItems="flex-start" justifyContent="flex-end" gap="20px">
      <Flex display="flex"  padding="12px 0px 0px 0px">Quantity:&nbsp;  <br /> <Input type="text" maxWidth="150px"  width="75%"  ref={quanbox} onKeyDown={e => checkquans(e)} /></Flex>
      <Flex flex="3" textAlign="right" padding="10px 10px 0px 0px"><Button type="button"onClick={e => newbook()}>New Book</Button></Flex>
      <Flex textAlign="right" padding="10px 10px 0px 0px"><Button type="button" onClick={e => bookinfo?.id ? updatebook() : savebook()} disabled={items.length === 0 ? true : false}>{bookinfo?.id ? "Update Bookinfo" : "Save Bookinfo"}</Button></Flex>
      </Flexed>
      <Collapse title={<div className="hand">Quantites {bookinfo.id && <> For {bookinfo.bookname}</>}</div>} > 
      <Tabs selectedIndex={activeIndex} onSelect={index => setActiveIndex(index)}>
 <TabList style={{marginBottom:"0"}}> 
  {
    items?.map((quan,index) => {
 return <Tab style={{padding:"4px 50px 0px 10px"}}>{quan.quantity}{activeIndex === index && items.length > 1 && <FiMinusCircle style={{position:"absolute",right:"10px",top:"7px",color:"rgb(255,0,0,.8)",fontSize:"calc(1rem + .2vw)"}} onClick={e => removequantity(quan.id)}/>}</Tab>
    })
  }
 
    </TabList>
    {
     items?.map((item,index) => {
      return <TabPanel style={{backgroundColor:"white",padding:"10px"}}><Bookitem  item={item} bookinfo={bookinfo} /></TabPanel>
      
      
    
      })
    }
 </Tabs>
 </Collapse> 




 <div style={{background:"white"}}>
  <Flexed bgColor="#4EC0AC" borderBottom="1px solid black" padding="3px 0px">
    <Flex>Quantity</Flex>
    <Flex>Labor %</Flex>
    <Flex>Labor Cost</Flex>
    <Flex>Labor Total</Flex>
    <Flex></Flex>
    <Flex>Mat %</Flex>
    <Flex>Mat Cost</Flex>
    <Flex>Mat Total</Flex>
    <Flex></Flex>
    <Flex>Total</Flex>
  </Flexed>
{
items.map(ite => {


return  <Flexed>
    <Flex>{ite.quantity}</Flex>
    <Flex><Input type="text" value={ite?.laborpercent} /></Flex>
    <Flex><Input type="text"  value={getlabor(ite).toFixed(2)}/></Flex>
    <Flex><Input type="text"  value={gettotlabor(ite,getlabor(ite)).toFixed(2)}/></Flex>
    <Flex></Flex>
    <Flex><Input type="text" value={ite?.materialpercent}/></Flex>
    <Flex><Input type="text"  value={getmat(ite).toFixed(2)}/></Flex>
    <Flex><Input type="text"  value={gettotmat(ite,getmat(ite)).toFixed(2)}/></Flex>
    <Flex></Flex>
    <Flex><Input type="text"  value={(gettotmat(ite,getmat(ite)) + gettotlabor(ite,getlabor(ite))).toFixed(2)}/></Flex> 

  </Flexed>
})
}
</div>

<br />
<br />
<div>
  Current Books
  <Flexed>
    <Flex></Flex>
  </Flexed>
</div>
<br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
      </div>
  )
}

export default Books