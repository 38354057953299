export const sortnum = ((col,order,list,setList,setOrder) => {
    if(order === "ASC"){
    const sorted = [...list].sort((a,b) =>
    parseInt(a[col]) > parseInt(b[col]) ? 1 : -1)
    setList(sorted);
    setOrder("DESC");
    }
    if(order === "DESC"){
        const sorted = [...list].sort((a,b) =>
        parseInt(a[col]) < parseInt(b[col]) ? 1 : -1)
        setList(sorted);
        setOrder("ASC");
        }
        })

 export const sortit = ((col,order,list,setList,setOrder,setWork) => {
    console.log(col)
     if(order === "ASC"){
            const sorted = [...list].sort((a,b) =>
            a[col] > b[col] ? 1 : -1)
            setList(sorted);
            setOrder("DESC");
            }
            if(order === "DESC"){
                const sorted = [...list].sort((a,b) =>
                a[col] < b[col] ? 1 : -1)
                setList(sorted);
                setOrder("ASC");
                }
              
                })

                export const sortdate = ((col,order,list,setList,setOrder) => {
                    if(order === "ASC"){
                           const sorted = [...list].sort((a,b) =>
                           Date.parse(a[col]) > Date.parse(b[col]) ? 1 : -1)
                           setList(sorted);
                           setOrder("DESC");
                           }
                           if(order === "DESC"){
                               const sorted = [...list].sort((a,b) =>
                               Date.parse(a[col]) < Date.parse(b[col]) ? 1 : -1)
                               setList(sorted);
                               setOrder("ASC");
                               }
                               })

                               
export const sortcustomername = ((col,order,list,setList,setOrder) => {
  console.log(list)
   if(order === "ASC"){
          const sorted = [...list].sort((a,b) =>
          a.customer.name > b.customer.name ? 1 : -1)
          setList(sorted);
          setOrder("DESC");
          }
          if(order === "DESC"){
              const sorted = [...list].sort((a,b) =>
                  a.customer.name < b.customer.name ? 1 : -1)
              setList(sorted);
              setOrder("ASC");
              }
              })

export const sortjobname = ((col,order,list,setList,setOrder) => {
                console.log(list)
 if(order === "ASC"){
        const sorted = [...list].sort((a,b) =>
        a.info.jobname > b.info.jobname ? 1 : -1)
        setList(sorted);
        setOrder("DESC");
        }
        if(order === "DESC"){
            const sorted = [...list].sort((a,b) =>
a.info.jobname < b.info.jobname ? 1 : -1)
            setList(sorted);
            setOrder("ASC");
            }
            })

            export const sortpo = ((col,order,list,setList,setOrder) => {
                if(order === "ASC"){
                const sorted = [...list].sort((a,b) =>
                a.info.custponum > b.info.custponum ? 1 : -1)
                setList(sorted);
                setOrder("DESC");
                }
                if(order === "DESC"){
                    const sorted = [...list].sort((a,b) =>
                    a.info.custponum < b.info.custponum ? 1 : -1)
                    setList(sorted);
                    setOrder("ASC");
                    }
                    })

                    export const sortjobnum = ((col,order,list,setList,setOrder) => {
                        if(order === "ASC"){
                        const sorted = [...list].sort((a,b) =>
                        a.info.custjobnum > b.info.custjobnum ? 1 : -1)
                        setList(sorted);
                        setOrder("DESC");
                        }
                        if(order === "DESC"){
                            const sorted = [...list].sort((a,b) =>
                            a.info.custjobnum < b.info.custjobnum ? 1 : -1)
                            setList(sorted);
                            setOrder("ASC");
                            }
                            })

export const sortquotedate = ((col,order,list,setList,setOrder) => {
 if(order === "ASC"){
        const sorted = [...list].sort((a,b) =>
        Date.parse(a[col]) > Date.parse(b[col]) ? 1 : -1)
        setList(sorted);
        setOrder("DESC");
        }
        if(order === "DESC"){
            const sorted = [...list].sort((a,b) =>
            Date.parse(a[col]) < Date.parse(b[col]) ? 1 : -1)
            setList(sorted);
            setOrder("ASC");
            }
            })