import { format } from 'date-fns'
import ColumnFilter from '../table/ColumnFilter';


export const Test1 =  [
    {
        Header:'',
        accessor:'id',
        placeholder:"ID",
     
    },
    {
        Header:'',
        accessor:'full_name',
        placeholder:"Name"
   
    },
    {
        Header:'',
        accessor:'start_date',
        placeholder:"Date",
  
        Cell:({value}) => {
return format(new Date(value), 'M/dd/yyyy')},

    },
    {
        Header:'',
        accessor:'email',
        placeholder:"Email"
 
    },
];


export const JobList =  [
    {
        Header:"",
        accessor:'ponum',
        placeholder:"PO#",
    Filter:ColumnFilter
    },
    {
        Header:"",
        placeholder:'Vendor',
        accessor:'vendor.company.name',
        Filter:ColumnFilter
    }
  
];


export const POLIST =  [
    
];



export const CUSTOMERLIST =  [
    {
        Header:"Customer",
        accessor:'name',
        placeholder:"Customer",
    Filter:ColumnFilter
    },
    {
        Header:"Address",
        placeholder:'address.address1',
        accessor:'Address',
        Filter:ColumnFilter
    },
    {
        Header:"Phone",
        accessor:'mainphone',
        placeholder:"Phone#",
    Filter:ColumnFilter
    },
    {
        Header:"Notes",
        placeholder:'',
        accessor:'notes',
        Filter:ColumnFilter
    }
];


export const JOBSHEADER =  [
    {
        Header:"Invoice Id",
        accessor:'jobid',
    Filter:ColumnFilter
    },
    {
        Header:"Customer",
        accessor:'customername',
        Filter:ColumnFilter
    },
    {
        Header:"Date",
        accessor:'lastdate',
    Filter:ColumnFilter
    },
    {
        Header:"Job Description",
        accessor:'description',
        Filter:ColumnFilter
    },
    {
        Header:"P.O.#",
        accessor:'po',
        Filter:ColumnFilter
    }
];