
import { createContext, useState, useEffect, useRef } from "react"
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { v4 as uuidv4 } from 'uuid';
import { wireo } from "../Pages/Est/Calcs";
import { currency } from "../Shared";



export const BookEstimate = createContext();


export const BookEstimateProvider = ({children}) => {
  const [text, setText] = useState({name:"",pages:"",thickness:""})
const [covers, setCovers] = useState({name:"",sheets:"",thickness:""})
const [other, setOther] = useState({name:"",pages:"",thickness:""})

const [textpunch, setTextpunch] = useState({type:"",perhour:25,punchsperhour:0})
const [coverspunch, setCoverspunch] = useState({type:"",perhour:25,punchsperhour:0})
const [otherpunch, setOtherpunch] = useState({type:"",perhour:25,punchsperhour:0})
const [tabspunch, setTabspunch] = useState({type:"",perhour:25,punchsperhour:0})

  const [book, setBook] = useState({});
  const [items, setItems] = useState([])
  const [bookinfo, setBookinfo] = useState({additionalthickness:.125,punchmr:50,coilmr:50,tabsthickness:.01,cuts:0});
  const [reset, setReset] = useState(false)
  const [thickness, setThickness] = useState()
  const [binding, setBinding] = useState({thickness:.125,type:"Wire-O"})
  const[totalpages,setTotalpages] = useState()
  const[coilinfo,setCoilinfo] = useState({})
  const[boxid,setBoxid] = useState(1) 


  //const[tabs,setTabs] = useState({thickness:.01,quantity:0,cost:.10,needed:0,total:0.00,totalthickness:0})
 
  const[slipsheet,setSlipsheet] = useState()
  const[punchmr,setPunchmr] = useState(50)
  const[coilmr,setCoilmr] = useState(50)
  const quanbox = useRef()
  const[boxz,setBoxz] = useState({boxsize:"9x12x12",boxid:1,boxcost:1.50,boxholds:1296})
  const[cutting,seCutting] = useState({quantity:0,total:0,cost:0,percut:10})
  const[currentbook,setCurrentbook] = useState({})

  const checkquans = (e) => { 
 
    if (e.key === "Enter" && e.target.value > "") {
      e.preventDefault()
if(items?.some(cat => cat.quantity === e.target.value)){
return
}else{
  e.preventDefault()
  createbook(e.target.value)
 // quanbox.current.value=""
}
    }
}

const createbook = (quantity) => {

  let punching

  if(parseInt(quantity) < 1000){
    punching={text:{punchtype:"Hand",punchspeed:3500,punchperhour:25,},covers:{punchtype:"Hand",punchspeed:3500,punchperhour:25,},other:{punchtype:"Hand",punchspeed:3500,punchperhour:25,},tabs:{punchtype:"Hand",punchspeed:3500,punchperhour:25,}}
  }else{
    punching={text:{punchtype:"Auto",punchspeed:7500,punchperhour:25,},covers:{punchtype:"Auto",punchspeed:7500,punchperhour:25,},other:{punchtype:"Auto",punchspeed:7500,punchperhour:25,},tabs:{punchtype:"Auto",punchspeed:7500,punchperhour:25,}}
  }
 let temp = {
  id:uuidv4(),
  quantity,
  coilinfo,
  coiling:{coiltype:"Roll"},
  punching,
  coil:{coilcost:0,coilinghour:120,coilingperhour:25},
  collate:{collatepu:0,perpack:.09,perhr:25,perpackperhour:360,cost:0.00},
  insert:{inserts:0,perinsert:.09,cost:0.00},
  tabs:{quantity:0,per:.09,totaltabs:0,cost:0},
  slipsheets:{quantity:0,per:.09,cost:0},
  boxes:boxz,
  cuts:{quantity:0,total:0,cost:0,percut:10},
 laborpercent:.07,
 lab:0,
 labtotal:0,
 mat:0,
 mattotal:0,
 materialpercent:.30,
 }
 const sorted = [...items,temp].sort((a,b) =>
  parseInt(a.quantity) > parseInt(b.quantity) ? 1 : -1)
  setItems(sorted);
  quanbox.current.value=""
setReset(!reset)
}





useEffect(() => {
let arr = []
items.map(item => {

  let temp = {
    id:uuidv4(),
    quantity:item?.quantity,
    coilinfo,
    coiling:coiling(item),
    punching:punching(item),
    coil:coil(item),
    collate:collate(item),
    boxes:getboxes(item),
    insert:getinsert(item),
    tabs:gettab(item),
    slipsheets:getslipsheets(item),
    cuts:getcuts(item),
  laborpercent:item.laborpercent,
  materialpercent:item.materialpercent,
   }
   arr.push(temp)
})

setItems(arr)

},[reset])


const getslipsheets = (item) => {

  let h 
if(bookinfo?.slipsheet){

  let a = parseInt(item.quantity) + 1
  let b = (a * parseFloat(item.slipsheets.per)).toFixed(2)
   h = {
  quantity:a,
  per:item.slipsheets.per,
  cost:b
  }
}else{

  h = {
    quantity:0,
    per:item.slipsheets.per,
    cost:0
    }
}
 
  return h
}



const getcuts = (item) => {
console.log(parseInt(bookinfo.cuts))
console.log(parseFloat(item?.cuts?.percut))
  let a = (parseInt(bookinfo.cuts) * parseFloat(item?.cuts?.percut))
  let b = parseInt(item.quantity) / 1000
  let c = (b *  a).toFixed(2)
  let g ={
    quantity:bookinfo.cuts,
    total:c,
 cost:a,
 percut:item?.cuts?.percut
  }
  return g
}



const getinsert = (item) => {

  let a = (parseFloat(item?.insert?.perinsert) * parseInt(bookinfo?.insert)).toFixed(2)
  let b =  parseInt(bookinfo?.insert) * parseInt(item?.quantity)
  let c=(b * parseFloat(item?.insert?.perinsert)).toFixed(2)

  let gg={
    inserts:parseInt(bookinfo?.insert) || 0,
    perinsert:item?.insert?.perinsert,
    totalinserts:b || 0,
    cost:c > 0 || c!=='NaN'? c : 0
  }
return gg
}


const gettab = (item) => {


 let a = parseInt(bookinfo?.tabs) * parseInt(item?.quantity)
 let b = a * parseFloat(item?.tabs?.per)
  let gg={
    quantity:item?.quantity,
    per:item?.tabs?.per,
    totaltabs:a,
    cost:(b).toFixed(2)
  }
return gg
}






const getlabor = (item) => {
  let aa = parseFloat(item?.punching?.other?.punchtotal) + parseFloat(item?.punching?.tabs?.punchtotal) + parseFloat(item?.punching?.covers?.punchtotal) + parseFloat(item?.punching?.text?.punchtotal) + parseFloat(item?.coil?.coilingtotal) + parseFloat(bookinfo?.punchmr) + parseFloat(bookinfo?.coilmr) + parseFloat(item.collate?.cost) + parseFloat(item.insert?.cost) + (parseFloat(item.cuts?.cost) || 0)

  return aa
}

const gettotlabor = (item,value) => {
let aa = (parseFloat(item?.laborpercent) + 1) * parseFloat(value)
return aa
}

const getmat = (item) => {
  let aa = (parseFloat(item?.coiling?.coiltotal) || 0) + (parseInt(bookinfo.tabs) * .09 || 0) + (parseFloat(item.boxes.cost) || 0) + (parseFloat(item.slipsheets.cost) || 0)
  return aa
}

const gettotmat = (item,value) => {
let aa = (parseFloat(item?.materialpercent) + 1) * parseFloat(value)
return aa
}


const labortotal = (item) => {
  let a = parseFloat(item.coiling?.coiltotal)
  let h = (parseFloat(item.laborpercent)+ 1) * a
  return h
}


const punching = (item) => {


  const get = (pages,ite) => {

let punch
if(ite?.punchtype === ""){
  punch = {
    punchtype:ite?.punchtype ,
    punchspeed:ite?.punchspeed,
    punchperhour:ite?.punchperhour,
    punchquantity:0,
    punchpages:0,
    punchtime:0 ,
   punchtotal:0 
 }
}else{
  punch = {
    punchtype:ite?.punchtype ,
    punchspeed:ite?.punchspeed,
    punchperhour:ite?.punchperhour,
    punchquantity:parseInt(pages) * parseInt(item?.quantity),
    punchpages:parseInt(pages) * parseInt(item?.quantity),
    punchtime:((parseInt(pages) * parseInt(item?.quantity)) / parseInt(ite?.punchspeed)) ,
   punchtotal:((parseInt(pages) * parseInt(item?.quantity)) / parseInt(ite?.punchspeed) * parseFloat(ite?.punchperhour)) 
 }
}
    
   return punch
  }
  
  let textp = parseFloat(bookinfo?.textpages) / 2 || 0
  let textc = parseFloat(bookinfo?.coverpages)  || 0
  let texto = parseFloat(bookinfo?.otherpages) / 2 || 0
  let textt = parseFloat(bookinfo?.tabs) || 0



let text=get(textp,item?.punching?.text)
let covers=get(textc,item?.punching?.covers)
let other=get(texto,item?.punching?.other)
let tabs=get(textt,item?.punching?.tabs)
let h = {text,covers,other,tabs}

return h
}



useEffect(() => {
  thicknesschange()
},[bookinfo?.textthickness,bookinfo?.coverthickness,bookinfo?.otherthickness,bookinfo?.additionalthickness,bookinfo?.textpages,bookinfo?.coverpages,bookinfo?.otherpages,bookinfo?.additionalthickness,bookinfo?.tabs])

useEffect(() => {
  coilchange()
},[bookinfo?.thickness])

useEffect(() => {
  holeschange()
},[bookinfo?.spine,coilinfo?.pitch])

useEffect(() => {
 setReset(!reset)
},[bookinfo?.collatepu,bookinfo?.insert,bookinfo?.slipsheet,boxz,bookinfo?.cuts,bookinfo.cuts])
useEffect(() => {

  setReset(!reset)
},[coilinfo])



const thicknesschange = () => {
let newtext = (parseInt(bookinfo?.textpages) / 2) * parseFloat(bookinfo?.textthickness) || 0
let newcovers = parseInt(bookinfo?.coverpages)  *  parseFloat(bookinfo?.coverthickness) || 0
let newother = (parseInt(bookinfo?.otherpages) / 2) *  parseFloat(bookinfo?.otherthickness) || 0
let newtabs = parseInt(bookinfo?.tabs) *  parseFloat(bookinfo?.tabsthickness) || 0
let addit = parseFloat(bookinfo?.additionalthickness) || 0

let newthickness = (newtext + newcovers + newother + newtabs + parseFloat(addit)).toFixed(3)

let newtextp = parseInt(bookinfo?.textpages) || 0
let newcoverp = parseInt(bookinfo?.coverpages) || 0
let newotherp = parseInt(bookinfo?.otherpages) || 0
let newtabsp = parseInt(bookinfo?.tabs) || 0
let newpages = newtextp + newcoverp + newotherp + newtabsp

setBookinfo({...bookinfo,thickness:newthickness,totalpages:newpages})
setReset(!reset)
}

let coilchange = () => {
let g
if(bookinfo?.thickness > 0){
  switch(binding.type){
    case "Wire-O":
       g = wireo.filter(filt => filt.decimal >= parseFloat(bookinfo?.thickness))
       break;
       case "Plastikol":
       g = wireo.filter(filt => filt.decimal >= parseFloat(bookinfo?.thickness))
       break;
       default:
        g = {
          boxcost:0.00,
          boxquantity:0,
          cost:0,
          decimal:0.0000,
          fraction:"",
          loopsperroll:0,
          mm:0,
          pitch:0,
          roll:0,
          spoolcost:0
        }
        break;
    }
}else{
  g = [{
    boxcost:0.00,
    boxquantity:0,
    cost:0,
    decimal:0.0000,
    fraction:"",
    loopsperroll:0,
    mm:0,
    pitch:0,
    roll:0,
    spoolcost:0
  }]
}

setCoilinfo(g[0])
setReset(!reset)
}





const changecoilinfo = (decimal) => {

  let g
  if(thickness > 0){
    switch(binding.type){
      case "Wire-O":
         g = wireo.filter(filt => filt.decimal === parseFloat(decimal))
         break;
         case "Plastikol":
         g = wireo.filter(filt => filt.decimal === parseFloat(decimal))
         break;
         default:
          g = {
            boxcost:0.00,
            boxquantity:0,
            cost:0,
            decimal:0.0000,
            fraction:"",
            loopsperroll:0,
            mm:0,
            pitch:0,
            roll:0,
            spoolcost:0
          }
          break;
      }
  }else{
    g = [{
      boxcost:0.00,
      boxquantity:0,
      cost:0,
      decimal:0.0000,
      fraction:"",
      loopsperroll:0,
      mm:0,
      pitch:0,
      roll:0,
      spoolcost:0
    }]

}
setCoilinfo(g[0])
setReset(!reset)
}

const holeschange = () => {
  if(bookinfo?.spine > 0 && coilinfo?.pitch > 0){
    let newspine =  parseInt(bookinfo?.spine)
    let newpitch = parseInt(coilinfo?.pitch)
    let newholes = (newspine * newpitch) - 1
   setBookinfo({...bookinfo,holes:newholes})
    setReset(!reset)
  }else{
    setBookinfo({...bookinfo,holes:0})
    setReset(!reset)
  }
}


 const coil = (item) => {

  let coilcount = parseInt(item?.quantity)

  let a = (parseInt(item?.quantity) / parseInt(item?.coil?.coilinghour)).toFixed(2)

  let b = a * parseFloat(item?.coil?.coilingperhour)

  let coiling = {
    coilingtype:"Hand",
    coilinghour:item?.coil?.coilinghour,
   coilingquantity:item?.quantity,
    coilingtime:(a),
    coilingperhour:item?.coil?.coilingperhour,
    coilingtotal:b.toFixed(2)
   }
  return coiling
 }


const coiling = (item) => {

  const gettotal = () => {
    let total = 0
switch(item?.coiling?.coiltype){
case "Boxes":
  total = ((parseInt(item?.quantity) /parseInt(coilinfo?.boxquantity)) * parseFloat(coilinfo?.boxcost)).toFixed(2);
  break;
  case "Roll":
    total = ((parseInt(bookinfo?.holes) * parseInt(item?.quantity)) / parseInt(coilinfo?.loopsperroll) * parseFloat(coilinfo?.spoolcost)).toFixed(2)
    break; 
    case "":
    total = 0;
    break;  
}
return total
  }

  let g ={
    coiltype:item?.coiling?.coiltype,
    coilloops:parseInt(bookinfo?.holes) * parseInt(item?.quantity),
    usage:((parseInt(bookinfo?.holes) * parseInt(item?.quantity)) / parseInt(coilinfo?.loopsperroll)).toFixed(3) ,
    numboxes:parseInt(item?.quantity)  /parseInt(coilinfo?.boxquantity),
    rollcost:((parseInt(bookinfo?.holes) * parseInt(item?.quantity)) / parseInt(coilinfo?.loopsperroll) * parseFloat(coilinfo?.spoolcost)).toFixed(2),
    boxcost:((parseInt(item?.quantity) /parseInt(coilinfo?.boxquantity)) * parseFloat(coilinfo?.boxcost)).toFixed(2),
    coiltotal:gettotal()
  }
return g
}


const collate = (item) => {
let packtotal = parseInt(bookinfo?.collatepu) * parseInt(item?.quantity).toFixed(2)
let time1 = ( packtotal / parseInt(item.collate?.perpackperhour)).toFixed(2)

let newcost = (time1 * parseFloat(item.collate?.perhr)).toFixed(2)


let gg = {
  collatetotal:packtotal || 0,
  collatepu:bookinfo?.collatepu,
  perpack:item.collate?.perpack,
  perhr:item.collate?.perhr,
  time:time1 !== 'NaN' ? time1 : 0.00,
  perpackperhour:item.collate?.perpackperhour,
  cost:newcost !== 'NaN' ? newcost : 0.00
}

return gg
}


const getboxes = (item) => {
  let a = Math.ceil((parseFloat(bookinfo?.length) * parseFloat(bookinfo?.width) * parseFloat(coilinfo?.decimal) * parseInt(item?.quantity)) / parseInt(boxz?.boxholds))
let b = a * boxz?.boxcost
 let box = {
   boxsize:boxz?.boxsize,
   boxid:boxz?.boxid,
   boxcost:(boxz?.boxcost),
   boxholds:boxz?.boxholds,
   needed:a || 0,
   cost:(b  || 0).toFixed(2)
 }
 
 return box
}

const gettabs = (item) => {

  let a = parseInt(item.tabs?.quantity) * parseInt(item?.quantity)
let b = a * parseFloat(item.tabs?.cost)
let r = {
  thickness:item.tabs?.thickness,
  quantity:item.tabs?.quantity,
  cost:item.tabs?.cost,
  needed:a,
  total:b.toFixed(2)
}  
return r
}

console.log(bookinfo)



 
  return (
    <BookEstimate.Provider value={{quanbox,checkquans,book,setBook,items,setItems,other,setOther,covers,setCovers,text,setText,thickness, setThickness,binding,setBinding,totalpages,coilinfo,setCoilinfo,reset,setReset,changecoilinfo,textpunch,setTextpunch,coverspunch,setCoverspunch,otherpunch,setOtherpunch,tabspunch,setTabspunch,slipsheet,setSlipsheet, punchmr,setPunchmr,coilmr,setCoilmr,currentbook,setCurrentbook,bookinfo, setBookinfo,getlabor,gettotlabor,getmat,gettotmat,setBoxz}}>
          <ToastContainer position="top-center" theme="dark" pauseOnHover={false}/>
        {children}
    </BookEstimate.Provider>
  )
}

export default BookEstimate