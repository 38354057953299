import React from 'react'
import { Flex, Flexed, PhoneNumber, Wrapper, currency, AddIcon, EditIcon, Scroll } from '../../Shared'
import { useEffect } from 'react'
import axios from 'axios'
import { useParams,useNavigate } from 'react-router-dom'
import { useState } from 'react'
import {GiHamburgerMenu} from 'react-icons/gi'
import Moment from 'react-moment'
import Modal from '../../modals/Modal'
import NewContact from './NewContact'
import NewLocation from './NewLocation'
import UpdateContact from './UpdateContact'
import UpdateLocation from './UpdateLocation'
import EditCustomer from './EditCustomer'
import {sortnum,sortit} from '../../Util/Sort'

const Customer = () => {
const nav = useNavigate()
    const custoid = useParams()
 const [customer, setCustomer] = useState([null,null])
 const [contacts, setContacts] = useState([])
 const [locations, setLocations] = useState([])
 const [quotes, setQuotes] = useState([])
 const [jobs, setJobs] = useState([])
 const [show, setShow] = useState(false)
 const [updateshow, setUpdateshow] = useState(false)
 const [updatecustomer, setUpdatecustomer] = useState(false)
 const [updatelocation, setUpdatelocation] = useState(false)
 const [locationshow, setLocationshow] = useState(false)
 const [currentcontact, setCurentcontact] = useState({})
 const [currentlocation, setCurentlocation] = useState({})
 const [reset, setReset] = useState(false)
 const [sales, setSales] = useState({})

 const [order, setOrder] = useState("Asc")


useEffect(() => {

    const getCustomer = async() => { 
        const y = {'id':custoid.id}
      const t =  await axios.post(` https://cfpapi-6ab062006fa0.herokuapp.com/customers/getcustomerinfo`,y)
       .then(res => {console.log(res.data);setCustomer(res.data);setContacts(res.data.contacts);setLocations(res.data.address)})
       .catch(err => console.log(err))
      
    }
    const getJobs = async() => { 
        const y = {'id':custoid.id}
       await axios.post(` https://cfpapi-6ab062006fa0.herokuapp.com/jobs/getcustomerjobs`,y)
       .then(res => {console.log(res.data);setJobs(res.data)})
       .catch(err => console.log(err))
    }


    const getAllSales = async() => { 
        const y = {'id':custoid.id}
        let r = 0;
       await axios.post(`https://cfpapi-6ab062006fa0.herokuapp.com/jobs/get/all/sales`,y)
       .then(res => {console.log(res.data);setSales(res.data) })
       .catch(err => console.log(err))
    }

    const getQuotes = async() => { 
        const y = {'id':custoid.id}
        await axios.post('http://localhost:5009/quotes/getallcustomerquotes',y)
      // await axios.post(`https://cfpapi-6ab062006fa0.herokuapp.com/jobs/get/all/sales`,y)
       .then(res => {console.log(res.data);setQuotes(res.data)})
       .catch(err => console.log(err))
    }
    getQuotes()
    getJobs()
    getCustomer()
    setCurentcontact({})
    getAllSales()
},[reset])







  return (
   <Wrapper padding="10px 10px"  >

<Modal show={show} header={`Add New Contact`} onCancel={() => {setShow(false)}} ><NewContact  reset={reset} setReset={setReset} customerid={custoid.id} onCancel={() => {setShow(false)}}/></Modal>
<Modal show={locationshow} header={`Add New Location`} onCancel={() => {setLocationshow(false)}} ><NewLocation reset={reset} setReset={setReset} customerid={custoid.id} onCancel={() => {setLocationshow(false)}}/></Modal>
<Modal show={updatecustomer} header={`Update Customer`} onCancel={() => {setUpdatecustomer(false)}} ><EditCustomer reset={reset} setReset={setReset} customerid={custoid.id} onCancel={() => {setUpdatecustomer(false)}}/></Modal>
<Modal show={updateshow} header={`Update Contact`} onCancel={() => {setUpdateshow(false)}} ><UpdateContact contact={currentcontact} reset={reset} setReset={setReset} customerid={custoid.id} onCancel={() => {setUpdateshow(false)}}/></Modal>
<Modal show={updatelocation} header={`Update Location`} onCancel={() => {setUpdatelocation(false)}} ><UpdateLocation location={currentlocation} reset={reset} setReset={setReset} customerid={custoid.id} onCancel={() => {setUpdatelocation(false)}}/></Modal>

<Flexed color="#418DA7" margin="0px" fontWeight="600">
<Flex fontSize="calc(.7rem + .4vw)" flex="3">Customer # 176</Flex>
<Flex padding="0px 0px 0px 0px" fontSize="calc(.7rem + .4vw)" flex="2">Main Address</Flex>
<Flex padding="0px 0px 0px 0px" fontSize="calc(.7rem + .4vw)">Main Phone</Flex>
<Flex padding="0px 0px 0px 0px" fontSize="calc(.7rem + .4vw)">Contact</Flex>
<Flex padding="0px 0px 0px 0px" fontSize="calc(.7rem + .4vw)" flex="1.5">Email</Flex>

</Flexed>

<Flexed color="rgb(0,0,0,.7)" padding="0px" >
<Flex flex="3" fontSize="calc(1.4rem + .4vw)" fontWeight="600" >{customer?.name}<br /><span onClick={e => setUpdatecustomer(true)} style={{bottom:"0px",width:"100%",position:"relative",fontWeight:"400",fontSize:"calc(.4rem + .4vw)",color:"limegreen",}}><GiHamburgerMenu style={{fontSize:"1rem",position:"relative",bottom:"-3px"}}/>&nbsp;Edit Details</span></Flex>
<Flex fontWeight="600" flex="2" padding="6px 0px 0px 0px" fontSize="calc(.6rem + .4vw)">{locations !== null && locations[0]?.address1 > "" && locations[0]?.address1+'\n' }{locations !== null && locations[0]?.address2 > ""  && locations[0]?.address2+'\n' || ""}{locations !== null && locations[0]?.city > "" && locations[0]?.city}{ locations !== null && locations[0]?.state >"" && ", "+locations[0]?.state}{locations !== null && locations[0]?.zip > "" && locations[0]?.zip} </Flex>
<Flex fontWeight="600" padding="6px 0px 0px 0px" fontSize="calc(.6rem + .4vw)">{PhoneNumber(locations !== null && locations[0]?.officephone)}</Flex>
<Flex fontWeight="600" padding="6px 0px 0px 0px" fontSize="calc(.6rem + .4vw)">{contacts !== null && contacts[0]?.name}</Flex>
<Flex fontWeight="600" padding="6px 0px 0px 0px" fontSize="calc(.6rem + .4vw)" flex="1.5"><span className="hand" onClick={() => window.location = `mailto:${contacts !== null && contacts[0]?.email}`}>{contacts !== null && contacts[0]?.email}</span></Flex>
</Flexed>

<Flexed bgColor="#8398AB" padding="3px 0px" fontWeight="bold" color="white" margin="25px 0px 0px 0px">
    <Flex textAlign="center">Today</Flex>
    <Flex textAlign="center">This Week</Flex>
    <Flex textAlign="center">Last Week</Flex>
    <Flex textAlign="center">This Month</Flex>
    <Flex textAlign="center">QTD</Flex>
    <Flex textAlign="center">YTD</Flex>
</Flexed>
<Flexed padding="3px 0px" fontWeight="bold"  color="rgb(0,0,0,.7)" bgColor="white">
    <Flex textAlign="center">${currency(sales?.today)}</Flex>
    <Flex textAlign="center">${currency(sales?.week)}</Flex>
    <Flex textAlign="center">${currency(sales?.lastweek)}</Flex>
    <Flex textAlign="center">${currency(sales?.month)}</Flex>
    <Flex textAlign="center">${currency(sales?.quarter)}</Flex>
    <Flex textAlign="center">${currency(sales?.year)}</Flex>
</Flexed>



<Flexed bgColor="#8398AB" padding="3px 0px" fontWeight="bold" color="white" margin="50px 0px 0px 0px">
    <Flex >Contacts</Flex><AddIcon onClick={() => setShow(true)}/>
</Flexed>
<Flexed padding="3px 0px" fontWeight="bold"  color="#418DA7" bgColor="white" margin="0">
    <Flex textAlign="left" flex="1.3">Name</Flex>
    <Flex textAlign="left">Title</Flex>
    <Flex textAlign="left">Office Phone</Flex>
    <Flex textAlign="left">Mobile Phone</Flex>
    <Flex  flex="1.4" textAlign="left">Email</Flex>
    <Flex textAlign="left"> Invoices</Flex>
    <Flex flex=".3" textAlign="left"> </Flex>
</Flexed>
{contacts?.map(contact => {
return(
<Flexed bgColor="white" className="hand" padding="3px 0px"  color="rgb(0,0,0,.7)" margin="0" borderBottom="1px solid rgb(0,0,0,.5)">
    <Flex textAlign="left" flex="1.3">{contact?.name}</Flex>
    <Flex textAlign="left">{contact?.title}</Flex>
    <Flex textAlign="left">{PhoneNumber(contact?.officephone)}{contact?.ext > "" && <>  Ext:{contact.ext}</>}</Flex>
    <Flex textAlign="left">{PhoneNumber(contact?.cell)}</Flex>
    <Flex  flex="1.4" textAlign="left" onClick={() => window.location = `mailto:${contact?.email}`}>{contact?.email}</Flex>
    <Flex >{contact?.invoices}</Flex>
    <Flex flex=".3"><EditIcon onClick={() => {setCurentcontact(contact);setUpdateshow(true)}}/></Flex>
</Flexed>
)
})
}




<Flexed bgColor="#8398AB" padding="3px 0px" fontWeight="bold" color="white" margin="50px 0px 0px 0px">
    <Flex >Locations</Flex><AddIcon onClick={() => setLocationshow(true)}/>
</Flexed>
<Flexed padding="3px 0px" fontWeight="bold"  color="#418DA7" bgColor="white" margin="0" >
    <Flex textAlign="left" flex="1.3">Name</Flex>
    <Flex textAlign="left" flex="1.3">Address</Flex>
    <Flex textAlign="left">City</Flex>
    <Flex textAlign="left">State</Flex>
    <Flex textAlign="left">Zip</Flex>
    <Flex textAlign="left">Phone</Flex>
    <Flex textAlign="left">Attn </Flex>
    <Flex flex=".3" textAlign="left"></Flex>
</Flexed>
{locations?.length > 0 && locations?.map(location => {
return(
<Flexed bgColor="white" className="hand" padding="3px 0px"  color="rgb(0,0,0,.7)" margin="0" borderBottom="1px solid rgb(0,0,0,.5)">
    <Flex  textAlign="left" flex="1.3">{location?.name}</Flex>
    <Flex textAlign="left" flex="1.3">{location?.address1}&nbsp;{location?.address2}</Flex>
    <Flex textAlign="left">{location?.city}</Flex>
    <Flex textAlign="left">{location?.state}</Flex>
    <Flex textAlign="left">{location?.zip}</Flex>
    <Flex  textAlign="left">{location?.officephone}</Flex>
    <Flex >{location?.attn}</Flex>
    <Flex flex=".3"><EditIcon onClick={() => {setCurentlocation(location);setUpdatelocation(true)}}/></Flex>
</Flexed>
)
})
}



<Flexed bgColor="#8398AB" padding="3px 0px" fontWeight="bold" color="white" margin="50px 0px 0px 0px">
    <Flex >Jobs</Flex><AddIcon onClick={() => nav(`/jobs/new?id=${customer.name}`)}/>
</Flexed>
<Scroll height="200px">
<Flexed padding="3px 0px" fontWeight="bold"  color="#418DA7" bgColor="white" margin="0">
    <Flex textAlign="left">Job #</Flex>
    <Flex textAlign="left">Quote #</Flex>
    <Flex textAlign="left">PO #</Flex>
    <Flex textAlign="left">Quantity</Flex>
    <Flex textAlign="left" flex="2">Job Name</Flex>
    <Flex textAlign="left">Status</Flex>
    <Flex textAlign="left">Amount</Flex>
    <Flex textAlign="left">Entered</Flex>
    <Flex textAlign="left">Due</Flex>
</Flexed>
{jobs?.map(job => {
return(
 
<Flexed bgColor="white" padding="3px 0px"  color="rgb(0,0,0,.7)"  margin="0px" className="hand" borderBottom="1px solid rgb(0,0,0,.5)"  onClick={() => nav(`/jobs/${job._id}`)}>
    <Flex textAlign="left">{job?.jobid}</Flex>
    <Flex textAlign="left">{job?.bid}</Flex>
    <Flex textAlign="left">{job?.po}</Flex>
    <Flex textAlign="left">{job?.quantity}</Flex>
    <Flex textAlign="left" flex="2">{job?.description}</Flex>
    <Flex textAlign="left">{job?.closed ? "Closed" : "Open"}</Flex>
    <Flex textAlign="left">{currency(job?.totaltotal) }</Flex>
    <Flex textAlign="left">{<Moment format="MM/DD/YYYY">{job?.lastdate}</Moment>}</Flex>
    <Flex textAlign="left">{<Moment format="MM/DD/YYYY">{job?.needby}</Moment>}</Flex>
</Flexed>

)
})
}
</Scroll>



<Flexed bgColor="#8398AB" padding="3px 0px" fontWeight="bold" color="white" margin="50px 0px 0px 0px">
    <Flex >Quotes</Flex><AddIcon />
</Flexed>
<Scroll height="200px">
<Flexed padding="3px 0px" fontWeight="bold"  color="#418DA7" bgColor="white" margin="0">
    <Flex textAlign="left"  >Quote #</Flex>
    <Flex textAlign="left">Quote Name</Flex>
    <Flex textAlign="left">Cust P.O.</Flex>
    <Flex textAlign="left">Cust Job #</Flex>
    <Flex textAlign="left">Enter Date</Flex>
    <Flex textAlign="left">Converted</Flex>
</Flexed>
{quotes?.map(quote => {
return(
<Flexed bgColor="white" padding="3px 0px"  color="rgb(0,0,0,.7)" margin="0" className="hand" borderBottom="1px solid rgb(0,0,0,.5)">
    <Flex textAlign="left" onClick={e => nav('/estimate/'+quote?.quotenum)}>{quote?.quotenum}</Flex>
    <Flex textAlign="left">{quote?.info?.jobname}</Flex>
    <Flex textAlign="left">{quote?.info?.custponum}</Flex>
    <Flex textAlign="left">{quote?.info?.custjobnum}</Flex>
    <Flex textAlign="left"><Moment format="MM/DD/YYYY">{quote?.createdAt}</Moment></Flex>
    <Flex textAlign="left"> Invoices</Flex>
</Flexed>
)
})
}

</Scroll>

<br /><br /><br /><br /><br /><br /><br /><br />






   </Wrapper>
  )
}

export default Customer