import React, { useContext, useEffect, useState } from 'react'
import { currency, Flex, Flexed, Input, Selectbox } from '../../Shared'
import BookEstimate from '../../Context/BookEstimate'
import { allboxes } from './Calcs'

const Bookitem = ({item}) => {

  
  const[punchinfo,setPunchinfo] = useState(item.punchinfo) 
  const[coilinginfo,setCoilinginfo] = useState(item.coilinginfo) 
  //const[punchtype,setPunchtype] = useState(item.punchinfo?.punchtype)
  const[punchperhour,setPunchperhour] = useState(item.punchinfo?.punchperhour)
  const[perhour,setPerhour] = useState(item.punchinfo?.perhour)

 // const[coilingtype,setCoilingtype] = useState(item.coilinginfo?.coilingtype)
  const[coilingperhour,setCoilingperhour] = useState(item.coilinginfo?.coilingperhour)
  const[coilingperhourrate,setCoilingperhourrate] = useState(item.coilinginfo?.perhour)

  const[boxinfo,setBoxinfo] = useState(item.boxinfo)
  const[boxtype,setBoxtype] = useState(item.boxinfo?.boxid) 
  const[boxesneeded,setBoxesneeded] = useState(item.boxinfo?.boxesneeded) 
 const[boxcost,setBoxcost] = useState(item.boxinfo?.boxcost) 
 const[coiltype,setCoiltype] = useState(item.coil?.coiltype) 
 const[punchtype,setPunchtype] = useState(item.punchtype) 
 const[coilingtype,setCoilingtype] = useState(item.coilingtype) 
 const[newitem,setNewitem] = useState() 


////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const {items,setItems,binding,reset,setReset,getcoilinfo,holes,coilinfo,tabs,setTabs,textpunch,setTextpunch,coverspunch,setCoverspunch,otherpunch,setOtherpunch,tabspunch,setTabspunch,punchmr,coilmr,bookinfo,setBookinfo,boxid,setBoxz,boxz} = useContext(BookEstimate)


useEffect(() => {
setNewitem(item)
},[item])

console.log(newitem)
console.log(bookinfo)
const changecoiltype = (e) => {
  let g = [...items]
  let objInd = g.findIndex(it => it.id === item.id)
  g[objInd].coiling.coiltype = e.target.value
 setItems(g)
 setReset(!reset)
}


const changetextpunch = (e,part) => {
  console.log(newitem)
  let temp = [...items]
  let h = 0
  let c = 0
  
 switch(e.target.value){
case "Auto":
 h = 7500
  break;
  case "Hand":
 h = 3500
  break;
  case "":
 h = 0
  break;
 }
 
 let objindex =  temp.findIndex((obj => obj.id === newitem?.id))
temp[objindex].punching[part].punchtype = e.target.value
temp[objindex].punching[part].punchspeed =h 
console.log(temp)
setItems(temp)
setReset(!reset)
}


const changeitem = (e,item,part,part2) => {
  console.log(item)

  let it = item
  let ttr = [...items]
  it[part][part2] = e.target.value
  let objindex = ttr.findIndex(index => index.id === item.id)
  console.log(objindex)
  ttr[objindex] = it
  setItems(ttr)
  setReset(!reset)

}


const changeboxes = (id) => {
  console.log(id)
  let y = allboxes.filter(filt => filt.boxid === parseInt(id))

 

  let a = Math.ceil((parseFloat(bookinfo?.length) * parseFloat(bookinfo?.width) * parseFloat(coilinfo?.decimal) * parseInt(item?.quantity)) / parseInt(y[0]?.boxholds))
  let b = a * y[0]?.boxcost

  let boxa = {
    boxsize:y[0]?.boxsize || 0,
    boxid:y[0]?.boxid || 0,
    boxcost:(y[0]?.boxcost || 0),
    boxholds:y[0]?.boxholds || 0,
  }
  console.log(boxa)
setBoxz(boxa)

}



  return (
    <>

<div style={{border:"1px solid #303030",margin:"8px 0px 0px 0px"}}>
    <Flexed bgColor="#4EC0AC" borderBottom="1px solid black">
    <Flex>{binding.type}</Flex>
    <Flex>Coil Loops</Flex>
    <Flex>Roll Usage:</Flex>
    <Flex># Box(s):</Flex>
    <Flex>Roll Cost:</Flex>
    <Flex>Box Cost</Flex>
    <Flex></Flex> 
    <Flex></Flex>
    <Flex>Total</Flex>
    </Flexed>
    <Flexed>
    <Flex><Selectbox value={newitem?.coiling?.coiltype} onChange={e => changecoiltype(e)}>
    <option value=""></option>
            <option value="Boxes">Boxes</option>
            <option value="Roll">Roll</option>
          </Selectbox></Flex>
    <Flex><Input value={newitem?.coiling?.coilloops}  disabled/></Flex>
    <Flex><Input value={newitem?.coiling?.usage} disabled/></Flex>
    
    <Flex><Input value={newitem?.coiling?.numboxes} /></Flex>  
    <Flex><Input value={newitem?.coiling?.rollcost} disabled/></Flex>
    <Flex><Input value={newitem?.coiling?.boxcost} disabled/></Flex>
    <Flex></Flex> 
    <Flex></Flex>
    <Flex><Input  value={newitem?.coiling?.coiltotal} disabled/></Flex>
    </Flexed>
  </div>
  

  <div style={{border:"1px solid #303030",margin:"8px 0px 0px 0px"}}>
    <Flexed bgColor="#4EC0AC" borderBottom="1px solid black">
    <Flex>Punching:</Flex>
    <Flex></Flex>
    <Flex>Sheets</Flex>
    <Flex>Punch / Hr</Flex>
    <Flex>Punch Time</Flex>
    <Flex>Per /Hr:</Flex>
 
   
    <Flex></Flex>
    <Flex></Flex>
    <Flex></Flex>
    <Flex>Cost</Flex> 
    </Flexed>

    <Flexed>
    <Flex>Text</Flex>
    <Flex ><Selectbox value={newitem?.punching?.text?.punchtype} onChange={e => changetextpunch(e,'text')}>
    <option value="">None</option>
    <option value="Auto">Auto</option>
      <option value="Hand">Hand</option>
      </Selectbox></Flex>
      <Flex><Input value={newitem?.punching?.text?.punchpages}  disabled/></Flex>
    <Flex><Input value={newitem?.punching?.text?.punchspeed}  /></Flex>
    <Flex><Input value={newitem?.punching?.text?.punchtime !== 'Infinity' ? (newitem?.punching?.text?.punchtime)?.toFixed(2) : 0} disabled/></Flex>
    <Flex><Input  value={newitem?.punching?.text?.punchperhour} /></Flex>
    
  
   
    <Flex></Flex>
    <Flex></Flex>
    <Flex></Flex>
    <Flex><Input value={newitem?.punching?.text?.punchtotal !== 'Infinity' ? (newitem?.punching?.text?.punchtotal)?.toFixed(2) : 0} disabled/></Flex> 
    </Flexed>
    <Flexed>
    <Flex>Covers</Flex>
    <Flex ><Selectbox value={newitem?.punching?.covers?.punchtype} onChange={e => changetextpunch(e,'covers')}>
    <option value="">None</option>
    <option value="Auto">Auto</option>
      <option value="Hand">Hand</option>
      </Selectbox></Flex>
      <Flex><Input value={newitem?.punching?.covers?.punchpages} disabled /></Flex>
    <Flex><Input value={newitem?.punching?.covers?.punchspeed}  /></Flex>
    <Flex><Input value={newitem?.punching?.covers?.punchtime !== 'Infinity' ? (newitem?.punching?.covers?.punchtime)?.toFixed(2) : 0} disabled/></Flex>
    <Flex><Input  value={newitem?.punching?.covers?.punchperhour} /></Flex>
    
  
   
    <Flex></Flex>
    <Flex></Flex>
    <Flex></Flex>
    <Flex><Input value={newitem?.punching?.covers?.punchtotal !== 'Infinity' ? (newitem?.punching?.covers?.punchtotal)?.toFixed(2) : 0} disabled/></Flex> 
    </Flexed>
    <Flexed>
    <Flex>Other</Flex>
    <Flex ><Selectbox value={newitem?.punching?.other?.punchtype} onChange={e => changetextpunch(e,'other')}>
    <option value="">None</option>
    <option value="Auto">Auto</option>
      <option value="Hand">Hand</option>
      </Selectbox></Flex>
      <Flex><Input value={newitem?.punching?.other?.punchpages} disabled /></Flex>
    <Flex><Input value={newitem?.punching?.other?.punchspeed}  /></Flex>
    <Flex><Input value={newitem?.punching?.other?.punchtime !== 'Infinity' ? (newitem?.punching?.other?.punchtime)?.toFixed(2) : 0} disabled/></Flex>
    <Flex><Input  value={newitem?.punching?.other?.punchperhour} /></Flex>
    
  
   
    <Flex></Flex>
    <Flex></Flex>
    <Flex></Flex>
    <Flex><Input value={newitem?.punching?.other?.punchtotal !== 'Infinity' ? (newitem?.punching?.other?.punchtotal)?.toFixed(2) : 0} disabled/></Flex> 
    </Flexed>
    <Flexed>
    <Flex>Tabs</Flex>
    <Flex ><Selectbox value={newitem?.punching?.tabs?.punchtype} onChange={e => changetextpunch(e,'tabs')}>
    <option value="">None</option>
    <option value="Auto">Auto</option>
      <option value="Hand">Hand</option>
      </Selectbox></Flex>
      <Flex><Input value={newitem?.punching?.tabs?.punchpages}  disabled/></Flex>
    <Flex><Input value={newitem?.punching?.tabs?.punchspeed}  /></Flex>
    <Flex><Input value={newitem?.punching?.tabs?.punchtime !== 'Infinity' ? (newitem?.punching?.tabs?.punchtime)?.toFixed(2) : 0} disabled/></Flex>
    <Flex><Input  value={newitem?.punching?.tabs?.punchperhour} /></Flex>
    <Flex></Flex>
    <Flex></Flex>
    <Flex></Flex>
    <Flex><Input value={newitem?.punching?.tabs?.punchtotal !== 'Infinity' ? (newitem?.punching?.tabs?.punchtotal)?.toFixed(2) : 0} disabled/></Flex> 
    </Flexed>
    <Flexed>
    <Flex></Flex>
    <Flex ></Flex>
      <Flex><Input value={newitem?.punching?.tabs?.punchpages + newitem?.punching?.covers?.punchpages + newitem?.punching?.text?.punchpages + newitem?.punching?.other?.punchpages}  disabled/></Flex>
    <Flex></Flex>
    <Flex><Input value={(parseFloat(newitem?.punching?.covers?.punchtime) + parseFloat(newitem?.punching?.other?.punchtime) + parseFloat(newitem?.punching?.text?.punchtime) + parseFloat(newitem?.punching?.tabs?.punchtime))?.toFixed(2) || 0} /></Flex>
    <Flex></Flex>
    
  
   
    <Flex></Flex>
    <Flex></Flex>
    <Flex></Flex>
    <Flex><Input value={(parseFloat(newitem?.punching?.covers?.punchtotal) + parseFloat(newitem?.punching?.other?.punchtotal) + parseFloat(newitem?.punching?.text?.punchtotal) + parseFloat(newitem?.punching?.tabs?.punchtotal))?.toFixed(2) || 0} disabled/></Flex> 
    </Flexed>
  </div>
  
  <div style={{border:"1px solid #303030",margin:"8px 0px 0px 0px"}}>
    <Flexed bgColor="#4EC0AC" borderBottom="1px solid black">
    <Flex>Coiling:</Flex>
    <Flex>Quantity</Flex>
    <Flex>Coil / Hr</Flex>
    <Flex> Coiling Hrs:</Flex>
    <Flex>Per /Hr:</Flex>

  
    <Flex></Flex>
    <Flex></Flex>
    <Flex></Flex>
    <Flex>Cost</Flex> 
    </Flexed>
    <Flexed>
    <Flex ></Flex>
      <Flex><Input value={newitem?.coil?.coilingquantity} disabled /></Flex>
    <Flex><Input value={newitem?.coil?.coilinghour}  onChange={e => changeitem(e,newitem,'coil','coilinghour')}/></Flex>
    <Flex><Input  value={newitem?.coil?.coilingtime} disabled/></Flex>
    <Flex><Input value={newitem?.coil?.coilingperhour} onChange={e => changeitem(e,newitem,'coil','coilingperhour')} /></Flex>
 
   
    <Flex></Flex>
    <Flex></Flex>
    <Flex></Flex>
    <Flex><Input value={newitem?.coil?.coilingtotal} disabled/></Flex> 
    </Flexed>

    
  </div>

  <div style={{border:"1px solid #303030",margin:"8px 0px 0px 0px"}}>
    <Flexed bgColor="#4EC0AC" borderBottom="1px solid black">
    <Flex>Collate:</Flex>
    <Flex>Quantity:</Flex>
    <Flex>Packs /Hr:</Flex>
    <Flex> Total Hrs:</Flex>
    <Flex>Per /Hr:</Flex>
    <Flex></Flex>
  
  
    <Flex></Flex>
    <Flex></Flex>
    <Flex>Cost</Flex> 
    </Flexed>
    <Flexed>
    <Flex ><Input value={bookinfo?.collatepu} onChange={e => setBookinfo({...bookinfo,collatepu:e.target.value})} /></Flex>
      <Flex><Input value={newitem?.collate?.collatetotal}  disabled/></Flex>
    <Flex><Input value={newitem?.collate?.perpackperhour} onChange={e => changeitem(e,newitem,'collate','perpackperhour')} /></Flex>
    <Flex><Input  value={newitem?.collate?.time === 'NaN' ? 0 : newitem?.collate?.time} disabled/></Flex>
    <Flex><Input value={newitem?.collate?.perhr} onChange={e => changeitem(e,newitem,'collate','perhr')}/></Flex>
 
   
    <Flex></Flex>
    <Flex></Flex>
    <Flex></Flex>
    <Flex><Input value={newitem?.collate?.cost === 'NaN' ? 0 : newitem?.collate?.cost} disabled/></Flex> 
    </Flexed>
  </div>

  <div style={{border:"1px solid #303030",margin:"8px 0px 0px 0px"}}>
    <Flexed bgColor="#4EC0AC" borderBottom="1px solid black">
    <Flex>Inserting:</Flex>
    <Flex>Quantity:</Flex>
    <Flex>Per Insert:</Flex>
    <Flex> Total:</Flex>
    <Flex></Flex>
    <Flex></Flex>
  
  
    <Flex></Flex>
    <Flex></Flex>
    <Flex>Cost</Flex> 
    </Flexed>
    <Flexed>
    <Flex ></Flex>
      <Flex><Input value={newitem?.insert?.inserts} onChange={e => setBookinfo({...bookinfo,insert:e.target.value})} /></Flex>
    <Flex><Input value={newitem?.insert?.perinsert}  onChange={e => changeitem(e,newitem,'insert','perinsert')}/></Flex>
    <Flex><Input  value={newitem?.insert?.totalinserts } disabled/></Flex>
    <Flex></Flex>
 
     
    <Flex></Flex>
    <Flex></Flex>
    <Flex></Flex>
    <Flex><Input value={newitem?.insert?.cost === 'NaN' ? 0 : newitem?.insert?.cost} disabled/></Flex> 
    </Flexed>
  </div>
  <div style={{border:"1px solid #303030",margin:"8px 0px 0px 0px"}}>
    <Flexed bgColor="#4EC0AC" borderBottom="1px solid black">
    <Flex>Trimming:</Flex>
    <Flex>Cuts:</Flex>
    <Flex>$ / Cut:</Flex>
    <Flex></Flex>
    <Flex></Flex>
    <Flex></Flex>
    <Flex></Flex>
    <Flex></Flex>
    <Flex>Cost</Flex> 
    </Flexed>
    <Flexed>
    <Flex></Flex>
   
    <Flex><Input value={newitem?.cuts?.quantity} onChange={e => setBookinfo({...bookinfo,cuts:e.target.value})}/></Flex>
    <Flex><Input value={newitem?.cuts?.percut} onChange={e => changeitem(e,newitem,'cuts','percut')}/></Flex>
    <Flex></Flex>
    <Flex></Flex>
    <Flex></Flex>
    <Flex></Flex>
    <Flex></Flex>
    <Flex><Input value={newitem?.cuts?.total} disabled/></Flex> 
    </Flexed>
  </div>

  <div style={{border:"1px solid #303030",margin:"8px 0px 0px 0px"}}>
    <Flexed bgColor="#4EC0AC" borderBottom="1px solid black">
    <Flex>Slip Sheets:</Flex>
    <Flex>Quantity:</Flex>
    <Flex>Per:</Flex>
    <Flex> </Flex>
    <Flex></Flex>
    <Flex></Flex>
  
  
    <Flex></Flex>
    <Flex></Flex>
    <Flex>Cost</Flex> 
    </Flexed>
    <Flexed>
    <Flex ></Flex>
      <Flex><Input value={newitem?.slipsheets?.quantity} disabled /></Flex>
    <Flex><Input value={newitem?.slipsheets?.per}  onChange={e => changeitem(e,newitem,'slipsheets','per')}/></Flex>
    <Flex></Flex>
    <Flex></Flex>
 
     
    <Flex></Flex>
    <Flex></Flex>
    <Flex></Flex>
    <Flex><Input value={newitem?.slipsheets?.cost === 'NaN' ? 0 : newitem?.slipsheets?.cost} disabled/></Flex> 
    </Flexed>
  </div>


  <div style={{border:"1px solid #303030",margin:"8px 0px 0px 0px"}}>
    <Flexed bgColor="#4EC0AC" borderBottom="1px solid black">
    <Flex>Boxes:</Flex>
    <Flex>Needed:</Flex>
    <Flex>$ / Box:</Flex>
    <Flex></Flex>
    <Flex></Flex>
    <Flex></Flex>
    <Flex></Flex>
    <Flex></Flex>
    <Flex>Cost</Flex> 
    </Flexed>
    <Flexed>
    <Flex><Selectbox value={newitem?.boxes?.boxid} onChange={e => changeboxes(e.target.value)}>
    <option value="0">None</option>
    {
      allboxes.map(box => {
        return  <option value={box?.boxid}>{box.boxsize}</option>
      })
}
      </Selectbox></Flex>
   
    <Flex><Input value={newitem?.boxes?.needed}/></Flex>
    <Flex><Input value={newitem?.boxes?.boxcost}/></Flex>
    <Flex></Flex>
    <Flex></Flex>
    <Flex></Flex>
    <Flex></Flex>
    <Flex></Flex>
    <Flex><Input value={newitem?.boxes?.cost} disabled/></Flex> 
    </Flexed>
  </div>




   

    <Flexed alignItems="flex-start" borderBottom="1px solid #333333" padding="0px 0px 5px 0px">
      <Flex flex="9"></Flex>
         <Flex><Input  /></Flex>
    </Flexed>

    </>
  )
}

export default Bookitem