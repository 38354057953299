import { AiFillFileAdd } from 'react-icons/ai'
import { useNavigate } from 'react-router-dom'
import { Flex, Flexed,Fixed,Input,List, Button } from '../../Shared'
import axios from 'axios'
import { useState,useEffect } from 'react'
import Moment from 'react-moment'
import {sortcustomername, sortdate, sortit, sortjobname, sortjobnum, sortnum, sortpo} from '../../Util/Sort'





const Quotes = () => {

const [quotes, setQuotes] = useState([])
const [search, setSearch] = useState("")
const [order, setOrder] = useState("ASC")
useEffect(() => {
const getAllJobs = async() => {
   // await axios.get(`http://localhost:5009/quotes/getopenquotes`)
    await axios.get(`https://cfpapi-6ab062006fa0.herokuapp.com/quotes/getopenquotes`)
    .then( res => setQuotes(res.data))
    .catch(err => console.log(err))
}
getAllJobs()
},[])


console.log(quotes)

    const nav = useNavigate()
  return (
<>
    <div style={{padding:"0px 20px",position:"relative"}}>

<Flexed padding="25px 0px 0px 0px">
  <Flex>CFP Quotes</Flex>
  <Flex><Button onClick={e => nav('/estimate')}>New Quote</Button></Flex>
</Flexed>
<Flexed padding="25px 0px 0px 0px">
  <Flex><Input type="text" height="30px" placeholder="Search Quotes       For dates use format 2025-01-02" onChange={e => setSearch(e.target.value)}/></Flex>
</Flexed>
<Flexed bgColor="#4EC0AC" borderBottom="1px solid black" margin="40px 0px 0px 0px">
   <Flex onClick={e => sortnum('quotenum',order,quotes,setQuotes,setOrder)}>Quote #</Flex>
   <Flex flex="2" onClick={e => sortcustomername('',order,quotes,setQuotes,setOrder)}>Customer</Flex>
   <Flex flex="2" onClick={e => sortjobname('',order,quotes,setQuotes,setOrder)}>Job Name</Flex>
   <Flex onClick={e => sortpo('',order,quotes,setQuotes,setOrder)}>Cust P.O. #</Flex>
   <Flex onClick={e => sortjobnum('',order,quotes,setQuotes,setOrder)}>Cust Job #</Flex>
  <Flex onClick={e => sortdate('createdAt',order,quotes,setQuotes,setOrder)}>Enter Date</Flex>
  <Flex onClick={e => sortdate('duedate',order,quotes,setQuotes,setOrder)}>Due Date</Flex>
  <Flex>Status</Flex>
  <Flex></Flex>
</Flexed>

{

    quotes?.filter(item => {return search.toLowerCase() === '' ? item : item.quotenum.toString().includes(search) || item?.customer?.name.toLowerCase().includes(search.toLowerCase()) || item?.info?.jobname.toLowerCase().includes(search.toLowerCase()) || item?.info?.custjobnum?.toString().includes(search.toLowerCase()) || item?.info?.custponum?.toString().includes(search.toLowerCase()) || item?.createdAt.toLowerCase().includes(search.toLowerCase()) || item?.duedate.toLowerCase().includes(search.toLowerCase())}).map(quote => {
return<Flexed padding="6px 0px">
   <Flex className="hand" onClick={e => nav('/estimate/'+quote.quotenum)}>{quote.quotenum}</Flex>
   <Flex flex="2">{quote?.customer?.name}</Flex>
   <Flex flex="2">{quote?.info?.jobname}</Flex>
   <Flex >{quote?.info?.custponum}</Flex>
   <Flex >{quote?.info?.custjobnum}</Flex>
  <Flex><Moment format="MM/DD/YYYY">{quote?.createdAt}</Moment></Flex>
  <Flex><Moment format="MM/DD/YYYY">{quote?.duedate}</Moment></Flex>
  <Flex>{quote?.status <= 0 ? "Open" : "Closed"}</Flex>
  <Flex><Button type="button" padding="3px 6px">Job</Button></Flex>
</Flexed>


    })
  }

</div>
</>
  )
}

export default Quotes