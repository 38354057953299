import React from 'react'
import { Flexed } from '../../Shared'

const Adddiecut = () => {
  return (
  <Flexed>
    test
  </Flexed>
  )
}

export default Adddiecut