import './App.css';
import Dashboard from './Dashboard';
import UserContext from './Context/UserContext'
import { useContext,useEffect } from 'react';
import Login from './Pages/Login';
import axios from 'axios';

function App() {

  const {userInfo} = useContext(UserContext);

  

  return (
    <div className="App">
  
  {sessionStorage.getItem('trackid') ? <Dashboard /> : <Login />}
   
    </div>
  );
}

export default App;
