import {useState,useEffect} from 'react'
import Table from '../../components/table/Table';
import  { CUSTOMERLIST } from '../../components/table/COLUMNS'
//import DATA from '../../components/table/DATA.json'
import DATA from '../../components/table/NewData.json'
import axios from 'axios'
import { Flex, Flexed, PhoneNumber, Wrapper,AddCustomer, Input, Fixed,List, currency } from '../../Shared';
import { useNavigate } from 'react-router-dom';



const Customers = () => {

    const [customers, setCustomers] = useState([])
    const [search, setSearch] = useState("")

const nav = useNavigate()
useEffect(() => {
const getCustomers = async() => {
//await axios.get(' https://cfpapi-6ab062006fa0.herokuapp.com/pos/getall')
await axios.get(' http://localhost:5009/pos/getall')
.then(res => {console.log(res.data);setCustomers(res.data)})
.catch((err) => console.log(err))
}
getCustomers()
},[])


  return (
    <div style={{padding:"0px 20px",position:"relative"}}>
      <Fixed padding="30px 0px 0px 0px" style={{zIndex:"10"}}>
  <Flexed>
            <Flex fontSize="calc(1.3rem + .4vw)" fontWeight="bold" color="var(--plsBlue)" textAlign="center" padding="10px 0px">CFP Purchase Orders</Flex>
        </Flexed>
        <Flexed >
          <Flex ><Input type="text" height="30px" placeholder="Search Purchase Orders" onChange={e => setSearch(e.target.value)}/></Flex>
         <Flex textAlign="right"></Flex> 
         </Flexed>
<Flexed bgColor="var(--plsGreen)" padding="6px 4px" color="white" margin="0">
<Flex >PO</Flex>
  <Flex flex="2">Customer</Flex>
  <Flex flex="5.3">
    <Flexed>
    <Flex flex="4">Items</Flex>
  <Flex>Quantity</Flex>
  <Flex>Cost</Flex>
  <Flex>Total</Flex>
  </Flexed>
  </Flex>
</Flexed>
</Fixed>
<List >
{customers?.filter(item => {return search.toLowerCase() === '' ? item : item.vendor.name?.toLowerCase().includes(search) || item.poid?.toString().includes(search)}).map((customer,index) => {
            return <>
            <Flexed key={index} className="hand" margin="0" padding="5px 0px"  borderBottom="1px solid lightgrey"  >
            <Flex onClick={() => nav(`/po/${customer._id}`)}>{customer?.poid}</Flex>
            <Flex flex="2" onClick={() => nav(`/po/${customer._id}`)}>
             {customer.vendor?.name}
              </Flex>
             <Flex flex="5.3">
   {customer.items.map(ite => { return <><Flexed><Flex  flex="4"><span style={{width:"100%"}}>{ite.name || ""}</span></Flex>
            <Flex onClick={() => nav(`/po/${customer._id}`)}>{ite.quantity || ""}
        </Flex>
            <Flex onClick={() => nav(`/po/${customer._id}`)}> {currency(ite.unitprice) || ""}
        </Flex> 
             <Flex onClick={() => nav(`/po/${customer._id}`)}>{currency(ite.extprice) || ""}
        </Flex> 
    </Flexed>

       </> })} 
   </Flex>
            </Flexed>
            
            </>
         }).reverse()}

</List>


</div>
  )
}

export default Customers