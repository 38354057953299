import styled  from "styled-components";
import "react-datepicker/dist/react-datepicker.css";
import TextareaAutosize from 'react-textarea-autosize';
import DatePicker from 'react-datepicker';
import {BsPlusCircle} from 'react-icons/bs'
import {AiOutlineEdit} from 'react-icons/ai'
import {IoIosBusiness} from 'react-icons/io'



export const Flexed = styled.div`
display: ${props => props.display || "flex"};
justify-content: ${props => props.justifyContent || ""};
flex-direction: ${props => props.flexDirection || "row"};
align-items: ${props => props.alignItems || "center"};
position: ${props => props.position || "relative"};
width: ${props => props.width || "100%"};
padding: ${props => props.padding || "5px 0px"};
background-color: ${props => props.bgColor || ""};
border: ${props => props.border || ""};
gap: ${props => props.gap || "5px"};
font-size:${props => props.fontSize || ""};
font-weight:${props => props.fontWeight || ""};
margin: ${props => props.margin || "0px 0px 10px 0px"};
height: ${props => props.height || ""};
border-bottom: ${props => props.borderBottom || ""};
border-top: ${props => props.borderTop || ""};
color: ${props => props.color || ""};
padding: ${props => props.padding || "0px"};
top: ${props => props.top || ""};
margin-bottom:${props => props.marginBottom || ""};

`;

export const Flex = styled.div`
flex: ${props => props.flex || "1"};
width: ${props => props.width || "100%"};
display: ${props => props.display || ""};
justify-content: ${props => props.justifyContent || ""};
align-items: ${props => props.alignItems || ""};
text-align: ${props => props.textAlign || "left"};
height: ${props => props.height || ""};
padding: ${props => props.padding || "0px 3px"};
position:${props => props.position || "relative"};
border: ${props => props.border || ""};
background-color: ${props => props.bgColor || ""};
color: ${props => props.color || ""};
border-bottom: ${props => props.borderBottom || ""};
min-width:${props => props.minWidth || ""};
font-size:${props => props.fontSize || ""};
font-weight:${props => props.fontWeight || ""};
margin: ${props => props.margin || "0px"};
text-transform: ${props => props.textTransform || "capitalize"};



`;

export const Fixed = styled.div`
position: ${props => props.position || "sticky"};
top:${props => props.top || "0"};
left:${props => props.left || ""};
right:${props => props.right || ""};
width: ${props => props.width || "100%"};
border: ${props => props.border || ""};
background-color:${props => props.bgColor || "#ECF0F1"};
padding: ${props => props.padding || "0px"};




`;


export const Img = styled.img`
position: ${props => props.position || "relative"};
width: ${props => props.width || "100%"};
height: ${props => props.height || ""};
border: ${props => props.border || ""};
padding:${props => props.padding || "3px 5px"};
margin:${props => props.margin || "0px"};
border-radius:${props => props.borderRadius || ""};
`;

export const List = styled.div`

position:relative;
z-index:1;

.hand:nth-child(odd){
  background-color:rgb(0,0,0,.1);
}

`;


export const Input = styled.input`
position: ${props => props.position || "relative"};
width:${props => props.width || "100%"};
padding:${props => props.padding || "3px 5px"};
max-width:${props => props.maxWidth || "100%"};
margin:${props => props.margin || "0px 0px 0px 0px"};
height: ${props => props.height || "30px"};
font-size:${props => props.fontSize || ""};
text-align: ${props => props.textAlign || "left"};
font-weight: ${props => props.fontWeight || ""};
text-transform: ${props => props.textTransform || "capitalize"};
outline: ${props => props.outline || "none"};
border: ${props => props.border || "none"};

border-bottom:${props => props.borderBottom || "1px solid grey"};
border-radius:5px;
:focus{
  border:2px solid var(--plsGreen);
}
`;

export const Button = styled.button`
width:${props => props.width || ""};
padding:${props => props.padding || "6px 25px"};
height: ${props => props.height || ""};
white-space: ${props => props.whiteSpace || "nowrap"};
top: ${props => props.top || ""};
bottom: ${props => props.bottom || ""};
text-transform: ${props => props.textTransform || "capitalize"};
font-weight:bold;
`;

export const Label = styled.label`

font-size: ${props => props.fontSize || "calc(.6rem + .4vw)"};
width: ${props => props.width || "100%"};
color: ${props => props.color || "inherit"};
font-weight: ${props => props.fontWeight || ""};
padding: ${props => props.padding || ""};
white-space: ${props => props.whiteSpace || "nowrap"};


`;

export const Datepick = styled(DatePicker)`
position:relative;
border-radius:5px;
width: ${props => props.width || "auto"};
height: ${props => props.height || "30px"};
border:1px solid rgb(0,0,0,.3);
outline: none;
margin: ${props => props.margin || ""};
padding: ${props => props.padding || "5px"};
font-weight: ${props => props.fontWeight || ""};
font-size: ${props => props.fontSize || "calc(.4rem + .5vw)"};
:focus{
  border:2px solid var(--plsGreen);
}

`;

export const Selectbox = styled.select`
position:relative;
border-radius:5px;
width: ${props => props.width || "100%"};
height: ${props => props.height || "30px"};
margin: ${props => props.margin || ""};
padding: ${props => props.padding || "0px"};
font-size: ${props => props.fontSize || ""};
border:1px solid rgb(0,0,0,.3);
outline: none;
text-align: ${props => props.textalign || "left"};
font-weight: ${props => props.fontWeight || ""};


:focus{
    border:2px solid var(--plsGreen);
  }

`;


export const Scroll = styled.div`
width:100%;
 overflow-y:auto;
 text-align:${props => props.textAlign || ""};
 height: ${props => props.height || ""};
 min-height: ${props => props.minHeight || ""};
 max-height: ${props => props.maxHeight || ""};
 position:relative;
 margin: ${props => props.margin || "0px"};
 &::-webkit-scrollbar {
  width: 0rem;
  background-color: #0E425A;
  border-radius: 1rem;


}
 `;

 
export const Tooltip = styled.div`
position:absolute;
width:100%;
display:none;



`;

export const Textarea = styled(TextareaAutosize)`

min-height:${props =>props.minHeight || "50px"};
width:${props =>props.width || "100%"};
outline:none;
border:${props =>props.border || "1px solid #c8c8c8"};
text-align:${props =>props.textAlign || "left"};
font-size:calc(.6rem + .5vw);
padding:${props =>props.padding || ""};
margin:${props =>props.margin || ""};
resize:none;
:focus{
    border:2px solid var(--plsGreen);
  }

`;

export const currency = (num) => {
  if(isNaN(num)){
return ""
  }else{
    return  parseFloat(num).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  }

}


export const Wrapper = styled.div`
width:100%;
position:relative;
padding:${props => props.padding || "10px 100px"};


`;

export const PhoneNumber = (value) => {
  if(!value || value === undefined){
  return value;
      }else{
          value = value.replace(/[^\d]/g, '');
          return `(${value.slice(0,3)}) ${value.slice(3,6)} - ${value.slice(6,10)}`;
      }
  
  }


  export const AddIcon = styled(BsPlusCircle) ` 
  width:${props => props.width || ""};
  font-size:${props => props.fontSize || "23px"};
  position:absolute;
  top:${props => props.top || "-11px"};
  right:${props => props.right || "-11px"};
color:white;
background-color:var(--plsBlue);
border-radius:50%;
  `;

  export const AddCustomer = styled(IoIosBusiness) ` 
  width:${props => props.width || ""};
  font-size:${props => props.fontSize || "2rem"};
  position:absolute;
  top:-11px;
  right:-5px;
color:var(--plsBlue);


  `;

  export const EditIcon = styled(AiOutlineEdit) ` 
  width:${props => props.width || ""};
  font-size:${props => props.fontSize || "23px"};
color:white;
background-color:rgb(14,66,90,.8);
border-radius:50%;
  `;






  